import { useState, useEffect } from 'react';
import toast from '../../lib/toast'
import AuthenticatedLayout from '../../components/layouts/AuthenticatedLayout'
import { search } from '../../api/data-products'
import DataProductsSection from '../../components/DataProductsSection'
import PageDescription from '../../components/PageDescription';
import DataProductDetails from '../../components/DataProductDetails';

const initialState = { searchInput: '', dataProducts: [], selectedDataProduct: null }

function SearchPage() {
  const [state, setState] = useState(initialState)

  async function fetchDataProducts() {
    const { data, error } = await search({ searchValue: state.searchInput })
    if (error) {
      setState({ ...state, dataProducts: [], selectedDataProduct: null })
      toast({ message: error.message })
    } else {
      setState({ ...state, dataProducts: data.results, selectedDataProduct: null })
    }
  }

  function openDataProductDetails({ dataProduct }) {
    setState({ ...state, selectedDataProduct: dataProduct })
  }

  useEffect(function () {
    fetchDataProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function handleSearchChange(e) {
    setState({ ...state, searchInput: e.target.value })
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      fetchDataProducts()
    }
  }

  const dataProductsSection = (
    <DataProductsSection
      dataProducts={state.dataProducts}
      openDataProductDetails={openDataProductDetails}
      selectedDataProduct={state.selectedDataProduct}
    />
  )

  return (
    <AuthenticatedLayout>
      <div className="flex flex-col grow">
        <PageDescription name='Search & Discover' description='Search and Discovery Data Products' />
        <div className="flex flex-col grow">
          <div className='flex flex-row'>
            <div className='flex basis-1/5'></div>
            <input
              value={state.searchInput} onChange={handleSearchChange} onKeyDown={handleKeyDown}
              className='border rounded-md border-grey p-2 basis-3/5' placeholder='Search for something...'
            />
            <div className='flex basis-1/5'></div>
          </div>
          <div className='flex flex-row px-7'>
            <div className='flex flex-col basis-1/3 mt-10 max-h-[calc(100vh-254px)] px-4 overflow-scroll' style={{maxWidth: '520px'}}>
              {dataProductsSection}
            </div>
            <DataProductDetails dataProduct={state.selectedDataProduct} />
          </div>
        </div>
      </div>
    </AuthenticatedLayout>
  )
}

export default SearchPage;