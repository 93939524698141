import React, { useEffect, useState } from "react";
import Tabs from "../Tabs";
import { search } from "../../api/workflow";
import {
  getLineageData,
  getUsageData,
  jobStatusInDatabricks,
} from "../../api/data-products";
import ReactFlow, { Background, MarkerType, Controls } from "reactflow";
import Loader from "../../assets/gif/loader.svg";
import moment from "moment";
import "reactflow/dist/style.css";
import DataProductQASection from "./DataProductQASection";
import DataProductRunSection from "./DataProductRunSection";
import { toast } from "react-toastify";
import FeedbackSection from "./FeedbackSection";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Link,
  TableContainer,
  TableHead,
  Paper,
  Tooltip,
  Chip,
} from "@mui/material";
import { axisClasses } from "@mui/x-charts";

const initialState = {
  workflowData: null,
  usageData: null,
  lineageData: [],
  mainNodes: [],
  mainEdges: [],
  isLoading: false,
};

const nodeColors = {
  "Internal Use": "#98FB98",
  Confidential: "#FFFF00",
  Secret: "#FA8072",
};

const chipColors = {
  default: "info",
  primary: "primary",
  "Internal Use": "success",
  Confidential: "error",
  Classified: "warning",
  Secret: "error",
};

export default function DataProductDetails({ dataProduct }) {
  const [state, setState] = useState(initialState);
  const dataProductSource = dataProduct?._source;

  useEffect(
    function () {
      async function fetchData() {
        //Observability Tab data
        let workflowData;
        if (
          dataProductSource.workflowID &&
          dataProductSource.workflowID !== "source_ui"
        ) {
          let { data, error } = await search({
            searchValue: dataProductSource.workflowID,
          });
          if (!error) {
            workflowData = data.data.results[0];
          }
        }

        //Usage Tab data
        let usageData;
        if (dataProductSource.commonName) {
          let { data, error } = await getUsageData({
            commonName: dataProductSource.commonName,
          });
          if (!error) {
            usageData = data.results;
          }
        }

        //Lineage Tab data
        let lineageData;
        let lineageDataSourceData;
        if (dataProductSource.fullName) {
          let { data, error } = await getLineageData({
            fullName: dataProductSource.fullName,
          });
          if (!error) {
            lineageData = data.results;
            lineageDataSourceData = data.datasource_data;
          }
        }
        // Run Tab Data
        let runDetailsData = {};
        if (dataProductSource?.jobID) {
          const { data, error } = await jobStatusInDatabricks(
            dataProductSource?.jobID
          );
          if (error) {
            toast({ message: error.message });
          } else {
            runDetailsData = data.data;
          }
        }

        setState({
          ...state,
          workflowData,
          usageData,
          lineageData,
          lineageDataSourceData,
          runDetailsData,
          isLoading: false,
        });
      }

      if (dataProduct) {
        setState({ ...state, isLoading: true });
        fetchData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataProduct]
  );

  const refreshRunDetails = async () => {
    if (dataProductSource?.jobID) {
      let runDetailsData = {};
      setState({ ...state, isLoading: true });
      const { data, error } = await jobStatusInDatabricks(
        dataProductSource?.jobID
      );
      if (error) {
        toast({ message: error.message });
      } else {
        runDetailsData = data.data;
      }
      setState({
        ...state,
        runDetailsData,
        isLoading: false,
      });
      console.log(runDetailsData);
    }
  };

  const metadataContent = (
    <Box
      sx={{
        // maxWidth: "calc(100vw / 2)",
        maxHeight: "calc(100vh - 290px)",
        overflowX: "auto",
        overflowY: "auto",
      }}
    >
      <Table>
        <TableBody>
          {dataProduct
            ? Object.keys(dataProduct._source).map((key, index) => {
                if (!["feedbacks", "dataQualityMetrics"].includes(key)) {
                  return (
                    <TableRow key={"metadata-" + index}>
                      <TableCell
                        sx={{
                          border: "1px solid",
                          borderColor: "grey.400",
                          borderRight: "none",
                          p: 1,
                          fontWeight: "bold",
                          fontSize:'14px'
                        }}
                      >
                        <span>{key}</span>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1px solid",
                          borderColor: "grey.400",
                          borderLeft: "none",
                          p: 1,
                        }}
                      >
                        {dataProduct?._source[key]?.indexOf("http") > -1 ? (
                          <Link
                            href={dataProduct._source[key]}
                            target="_blank"
                            rel="noreferrer"
                            underline="always"
                            color="primary"
                            fontSize={14}
                          >
                            {dataProduct._source[key].split("/").pop()}
                          </Link>
                        ) : [
                            "confidentiality",
                            "format",
                            "sourceSystem",
                            "locationTag",
                            "classification",
                            "maturityLevel",
                            "size",
                            "appSource",
                          ].includes(key) ? (
                          <Chip
                            label={dataProduct._source[key]}
                            variant="outlined"
                            color={
                              chipColors[dataProduct._source[key]] ?? "primary"
                            }
                          />
                        ) : key === "jobID" ? (
                          <Link
                            href={`https://dbc-8702f8dc-dbfa.cloud.databricks.com/jobs/${dataProduct._source[key]}`}
                            target="_blank"
                            rel="noreferrer"
                            underline="always"
                            color="primary"
                            fontSize={16}
                          >
                            {dataProduct._source[key]}
                          </Link>
                        ) : (
                          <Typography fontSize={14}>{dataProduct._source[key]}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
                return null;
              })
            : null}
        </TableBody>
      </Table>
    </Box>
  );

  const DataQualityChecksTable = ({ data }) => {
    return (
      <TableContainer
        sx={{
          maxHeight: "calc(100vh - 340px)",
          border: "1px solid",
          borderColor: "grey.400",
          fontSize: "16px !important",
          p: 1,
        }}
      >
        <Table aria-label="data quality checks table">
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  paddingX: 1,
                }}
              >
                Total Row Count
              </TableCell>
              <TableCell align="right" sx={{ padding: 0 }}>
                {data["Data Quality Checks Results"]["Total Row Count"]}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  paddingX: 1,
                }}
              >
                Completeness Checks
              </TableCell>
              <TableCell align="right" sx={{ padding: 0 }}>
                <Table>
                  <TableBody>
                    {Object.entries(
                      data["Data Quality Checks Results"][
                        "Completeness Checks"
                      ][0]
                    ).map(([key, value], index) => (
                      <TableRow
                        key={key}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell sx={{ padding: 0, pt: 1, pb: 1 }}>
                          {key}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ padding: 0, pt: 1, pb: 1 }}
                        >
                          {value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  paddingX: 1,
                }}
              >
                Duplicate Rows Count
              </TableCell>
              <TableCell align="right" sx={{ padding: 0 }}>
                {data["Data Quality Checks Results"]["Duplicate Rows Count"]}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  paddingX: 1,
                }}
              >
                Consistency Checks
              </TableCell>
              <TableCell align="right" sx={{ padding: 0 }}>
                <Table>
                  <TableBody>
                    {Object.entries(
                      data["Data Quality Checks Results"]["Consistency Checks"]
                    ).map(([key, value], index) => (
                      <TableRow
                        key={key}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell sx={{ padding: 0, pt: 1, pb: 1 }}>
                          {key}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ padding: 0, pt: 1, pb: 1 }}
                        >
                          {value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  paddingX: 1,
                }}
              >
                Data Types
              </TableCell>
              <TableCell align="right" sx={{ padding: 0 }}>
                <Table>
                  <TableBody>
                    {Object.entries(
                      data["Data Quality Checks Results"]["Data Types"]
                    ).map(([key, value], index) => (
                      <TableRow
                        key={key}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell sx={{ padding: 0, pt: 1, pb: 1 }}>
                          {key}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ padding: 0, pt: 1, pb: 1 }}
                        >
                          {value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  paddingX: 1,
                  border: 0,
                }}
              >
                Range and Pattern Checks
              </TableCell>
              <TableCell align="right" sx={{ padding: 0, border: 0 }}>
                <Table>
                  <TableBody>
                    {Object.entries(
                      data["Data Quality Checks Results"][
                        "Range and Pattern Checks"
                      ]
                    ).map(([key, value], index) => (
                      <TableRow
                        key={key}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell sx={{ padding: 0, pt: 1, pb: 1 }}>
                          {key}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ padding: 0, pt: 1, pb: 1 }}
                        >
                          {value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  let profileAndQualityContent;

  try {
    const dataQualityMetrics = dataProduct?._source?.dataQualityMetrics;

    if (dataQualityMetrics) {
      // Try to parse the dataQualityMetrics
      const parsedData = JSON.parse(dataQualityMetrics);

      // If parsedData is an object, render the DataQualityChecksTable
      if (typeof parsedData === "object" && parsedData !== null) {
        profileAndQualityContent = <DataQualityChecksTable data={parsedData} />;
      } else {
        // If it's not an object, display it as a string
        profileAndQualityContent = <>{String(dataQualityMetrics)}</>;
      }
    } else {
      profileAndQualityContent = <>No Metrics</>;
    }
  } catch (error) {
    // If JSON parsing fails, display the raw string
    profileAndQualityContent = (
      <>{String(dataProduct?._source?.dataQualityMetrics)}</>
    );
  }

  function processLineageData() {
    if (state.lineageData) {
      // Filter out nodes with empty objects
      const filteredLineageData = state.lineageData.filter(
        (node) => Object.keys(node).length > 0
      );
      processLineageDataHelper(filteredLineageData);
    }
  }

  const globalNodes = [],
    globalEdges = [];
  let nodeCount = 1;

  function processLineageDataHelper(
    initialNodes,
    parent,
    positionX = 0,
    positionY = 0
  ) {
    const initialPositionX = positionX,
      initialPositionY = positionY;
    let tempPositionX;
    tempPositionX = positionX;

    for (const node of initialNodes) {
      const duplicates = globalNodes
        .filter((globalNode) => Object.keys(globalNode).length > 0)
        .filter((globalNode) => globalNode.table_name === node.table_name);
      let idAddedNode;
      if (duplicates.length > 0) {
        idAddedNode = duplicates[0];
      } else {
        const names = node?.table_name?.split(".");

        const formattedNames = (
          <>
            <div>
              {names?.slice(0, 2).map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  {index < names.slice(0, 2).length - 1 && "."}
                  {/* Add a dot between the first two names */}
                </React.Fragment>
              ))}
            </div>
            <div>
              {names?.slice(2).map((name, index) => (
                <span key={index} style={{ fontWeight: "bold" }}>
                  {name}
                  {index < names.slice(2).length - 1 && "."}
                </span>
              ))}
            </div>
          </>
        );

        idAddedNode = {
          id: `${nodeCount}`,
          position: {
            x: -tempPositionX,
            y: -initialPositionY,
          },
          table_name: node.table_name,
          data: {
            label: (
              <>
                {formattedNames}
                <div>
                  {state.lineageDataSourceData[node.table_name]?.creationDate
                    ? moment(
                        state.lineageDataSourceData[node.table_name]
                          .creationDate
                      ).format("MMMM D, YYYY h:mm A")
                    : "No Creation Date"}{" "}
                </div>
                <div>
                  <span style={{ fontWeight: "bold" }}>Classification: </span>
                  {state.lineageDataSourceData[node.table_name]?.classification
                    ? state.lineageDataSourceData[node.table_name]
                        .classification
                    : "N/A"}{" "}
                </div>
              </>
            ),
          },
          draggable: false,
          selectable: false,
          style: {
            width: "190px",
            backgroundColor: nodeColors.hasOwnProperty(
              state.lineageDataSourceData[node.table_name]?.classification
            )
              ? nodeColors[
                  state.lineageDataSourceData[node.table_name].classification
                ]
              : "#FFFFFF",
          },
        };
        nodeCount++;
        tempPositionX = tempPositionX + 200;
        globalNodes.push(idAddedNode);
      }

      if (parent) {
        globalEdges.push({
          id: `e${parent.id}-${idAddedNode.id}`,
          source: idAddedNode.id,
          target: parent.id,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 30,
            height: 30,
            color: "black",
          },
        });
      }
      if (node.parent) {
        processLineageDataHelper(
          node.parent.filter((n) => Object.keys(n).length > 0), // Filter child nodes
          idAddedNode,
          initialPositionX,
          initialPositionY + 150
        );
      }
    }
  }

  processLineageData();

  const lineageContent = (
    <div className="h-[500px] w-[800px]">
      {globalNodes.length > 0 && (
        <ReactFlow nodes={globalNodes} edges={globalEdges} fitView>
          <Background color="#aaa" gap={16} />
          <Controls />
        </ReactFlow>
      )}
      {globalNodes.length === 0 && "No Lineage Data Available"}
    </div>
  );

  const usageContent = state.usageData && (
    <div style={{ display: "flex", gap: "20px" }}>
      <div style={{ display: "inline" }}>
        <table className="max-h-[calc(100vh-296px)] mt-10">
          <thead>
            <tr>
              <th
                className="border border-y-gray-400 border-l-gray-400 border-r-gray-400 p-2 font-bold uppercase text-black"
                align="left"
              >
                Operation
              </th>
              <th className="border border-y-gray-400 border-l-gray-400 border-r-gray-400 p-2 font-bold uppercase text-black">
                Count
              </th>
            </tr>
          </thead>
          <tbody>
            {state.usageData.map((usageRecord, index) => (
              <tr key={"usage-" + index}>
                <td
                  className="border border-y-gray-400 border-l-gray-400 border-r-gray-400 p-2"
                  align="left"
                >
                  {usageRecord.key}
                </td>
                <td className="border border-y-gray-400 border-l-gray-400 border-r-gray-400 p-2">
                  {usageRecord.doc_count}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ display: "inline" }}>
        <BarChart
          series={[
            {
              data: state.usageData.map((record) => record.doc_count),
              color: "#017A49",
            },
          ]}
          width={650}
          height={400}
          yAxis={[
            {
              data: state.usageData.map((record) => record.key),
              scaleType: "band",
            },
          ]}
          layout="horizontal"
          style={{ display: "inline" }}
          sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: "rotate(-90deg) translate(0px, -20px)",
            },
            [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
              transform: "rotateZ(-45deg)",
            },
          }}
          margin={{ left: 250 }}
        />
      </div>
    </div>
  );

  const sortedWorkflowData = state.workflowData?.sort((a, b) => {
    const timestampA = moment(
      a._source.workflows?.[0]?.workflowEventTimestamp ||
        a._source.workflowState?.eventTimestamp
    ).valueOf();

    const timestampB = moment(
      b._source.workflows?.[0]?.workflowEventTimestamp ||
        b._source.workflowState?.eventTimestamp
    ).valueOf();

    return timestampA - timestampB;
  });
  console.log(sortedWorkflowData);
  const observabilityContent = sortedWorkflowData ? (
    <TableContainer
      sx={{ border: "0.5px solid", borderColor: "grey.400" }}
      style={{ maxHeight: "calc(100vh - 340px)", overflowY: "auto" }}
    >
      <Table stickyHeader sx={{ borderCollapse: "collapse" }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                border: "1px solid",
                borderColor: "grey.400",
                fontWeight: "bold",
                fontSize: "16px",
                p: 1,
              }}
              align="center"
            >
              ID
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid",
                borderColor: "grey.400",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              align="center"
            >
              WORKFLOW STATUS
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid",
                borderColor: "grey.400",
                fontWeight: "bold",
                fontSize: "16px",
                p: 1,
              }}
              align="center"
            >
              DESCRIPTION
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid",
                borderColor: "grey.400",
                fontWeight: "bold",
                fontSize: "16px",
                p: 1,
              }}
              align="center"
            >
              PROCESSED RECORDS
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid",
                borderColor: "grey.400",
                fontWeight: "bold",
                fontSize: "16px",
                p: 1,
              }}
              align="center"
            >
              DETAILS
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid",
                borderColor: "grey.400",
                fontWeight: "bold",
                fontSize: "16px",
                p: 1,
              }}
              align="center"
            >
              EXECUTION DURATION
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid",
                borderColor: "grey.400",
                fontWeight: "bold",
                fontSize: "16px",
                p: 1,
              }}
              align="center"
            >
              EVENT TIMESTAMP
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedWorkflowData?.map((workflow, index) => {
            const source = workflow._source;
            if (!source) return null;

            const workflows = source.workflows?.[0];
            const workflowState = workflows || source.workflowState;

            if (!workflowState) return null;

            const {
              metadata,
              workflowStatus,
              workflowEventTimestamp,
              eventTimestamp,
              executionDuration,
            } = workflowState;

            return (
              <TableRow key={`observability-${index}`}>
                <TableCell
                  align="center"
                  sx={{ border: "1px solid", borderColor: "grey.400", py: 0 }}
                >
                  <Tooltip title={workflow._id}>
                    <InfoOutlinedIcon fontSize="small" color="primary" />
                  </Tooltip>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ border: "1px solid", borderColor: "grey.400", py: 0 }}
                >
                  <p style={{paddingLeft: '2px', paddingRight: '2px'}}>{workflowStatus}</p>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ border: "1px solid", borderColor: "grey.400", py: 0 }}
                >
                  {metadata?.description && (
                    <Tooltip title={metadata.description}>
                      <InfoOutlinedIcon fontSize="small" color="primary" />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ border: "1px solid", borderColor: "grey.400", py: 0 }}
                >
                  {metadata?.processedRecords}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ border: "1px solid", borderColor: "grey.400", py: 0 }}
                >
                  {metadata?.details && (
                    <Tooltip title={metadata.details}>
                      <InfoOutlinedIcon fontSize="small" color="primary" />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ border: "1px solid", borderColor: "grey.400", py: 0 }}
                >
                  {Boolean(metadata?.executionDuration) &&
                    `${Math.round(
                      parseFloat(metadata?.executionDuration) ?? 0
                    )} seconds`}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ border: "1px solid", borderColor: "grey.400", py: 0 }}
                >
                  {moment(workflowEventTimestamp || eventTimestamp).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <>No Observability Data Available</>
  );

  const feedbackContent = <FeedbackSection dataProduct={dataProduct} />;

  const qnaContent = (
    <DataProductQASection
      workflowData={state.workflowData}
      usageData={state.usageData}
      lineageData={state.lineageData}
      lineageDataSourceData={state.lineageDataSourceData}
      dataProductSource={dataProductSource}
      runDetailsData={state.runDetailsData}
    />
  );

  const runContent = (
    <>
      {dataProductSource?.jobID && state?.runDetailsData ? (
        <DataProductRunSection
          dataProductSource={dataProductSource}
          details={state?.runDetailsData}
          refreshRunDetails={refreshRunDetails}
        />
      ) : (
        "Run Information Not Available"
      )}
    </>
  );

  const loaderComponent = (
    <img src={Loader} className="w-[50px]" alt="loading" />
  );

  let tabsContent;
  if (state.isLoading) {
    tabsContent = [
      metadataContent,
      profileAndQualityContent,
      loaderComponent,
      loaderComponent,
      loaderComponent,
      loaderComponent,
      feedbackContent,
      loaderComponent,
    ];
  } else {
    tabsContent = [
      metadataContent,
      profileAndQualityContent,
      lineageContent,
      runContent,
      observabilityContent,
      usageContent,
      feedbackContent,
      qnaContent,
    ];
  }

  return (
    <div className="flex flex-col basis-2/3 pl-2 py-2 overflow-scroll-x overflow-scroll-y">
      <Tabs
        tabContent={tabsContent}
        shouldShowTabContent={dataProduct}
        tabs={[
          "Metadata",
          "Profile & Quality",
          "Lineage",
          "Runs",
          "Observability",
          "Usage",
          "Feedback",
          "Conversational AI",
        ]}
      />
    </div>
  );
}
