import React, { useState, useEffect } from "react";
import CustomButton from "../../../components/CustomButton";
import toast from "../../../lib/toast";
import Loader from "../../../assets/gif/loader.svg";
import { getDefaultProblemStatement } from "../../../api/util";
import { getCustomSummaryV1 } from "../../../api/data-products";
import ProblemStatement from "../../../components/ProblemStatement";
import OutputHeader from "../../../components/OutputHeader";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const calculateComputeCost = (milliseconds) => {
  const duration = moment.duration(milliseconds);
  const minutes = Math.floor(duration.asMinutes() * 1.2);
  const seconds = Math.floor(duration.seconds() * 1.2);
  return `${minutes}.${seconds}`;
};

const initialState = {
  statement: "",
  chatHistory: [],
  isLoading: false,
  currentQuestion: "",
};

const DataProductQASection = ({
  workflowData,
  usageData,
  lineageData,
  lineageDataSourceData,
  dataProductSource,
  runDetailsData,
}) => {
  const [state, setState] = useState(initialState);
  const [metadataSwitchToggle, setMetadataSwitchToggle] = useState(false);

  useEffect(function () {
    if (!sessionStorage.getItem('sessionId')) {
      // Generate a new UUID for the session ID
      const session_id = uuidv4()
      // Store the session ID in session storage
      sessionStorage.setItem('sessionId', session_id);
    }
    async function getInitialData() {
      const { data, error } = await getDefaultProblemStatement(
        dataProductSource?.name
      );
      if (error) {
        toast({ message: error.message });
      } else {
        setState({ ...state, statement: data.defaultStatement });
      }
    }
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setProblemStatement(statement) {
    setState({ ...state, statement });
  }

  function handleClearing() {
    setState({ ...state, chatHistory: [] });
  }

  function askQuestion() {
    if (!state.currentQuestion) {
      return;
    }
    const question = state.currentQuestion;
    const currentChatHistory = state.chatHistory;
    setState({
      ...state,
      isLoading: true,
      currentQuestion: "",
      chatHistory: [...currentChatHistory, { question }],
    });
    setTimeout(function () {
      scrollToBottom();
    }, 100);
    async function getAnswer() {
      const { data, error } = await getCustomSummaryV1({
        chatHistory: metadataSwitchToggle
          ? currentChatHistory
          : currentChatHistory.filter((x) => !x.dataAssisted),
        question,
        document: metadataSwitchToggle ? state.statement : "",
        metadata: {
          workflowDetails: workflowData,
          apiUsage: usageData.map((x) => {
            const y = {
              api: x.key,
              count: x.doc_count,
            };
            return y;
          }),
          lineage: lineageData,
          metadata: dataProductSource,
          jobRunExecutionDetails: {
            ...runDetailsData,
            last_run_cost: runDetailsData?.runDetails?.runs[0]?.run_duration
              ? `$${calculateComputeCost(
                  runDetailsData?.runDetails?.runs[0]?.run_duration
                )}`
              : "N/A",
            total_run_cost: runDetailsData?.runDetails?.total_run_duration
              ? `$${calculateComputeCost(
                  runDetailsData?.runDetails?.total_run_duration
                )}`
              : "N/A",
          },
          lineageDataSourceDetails: lineageDataSourceData,
        },
      });

      if (error) {
        setState({
          ...state,
          chatHistory: [...currentChatHistory, { question }],
          currentQuestion: "",
          isLoading: false,
        });
        toast({ message: error.message });
      } else {
        const updatedChatHistory = [
          ...currentChatHistory,
          {
            question,
            answer: data.text,
            dataAssisted: metadataSwitchToggle,
          },
        ];
        setState({
          ...state,
          chatHistory: updatedChatHistory,
          currentQuestion: "",
          isLoading: false,
        });
      }
      setTimeout(function () {
        scrollToBottom();
      }, 100);
    }
    getAnswer();
  }

  function handleCurrentQuestionChange(currentQuestion) {
    setState({ ...state, currentQuestion });
  }

  function getAIMessage(message, index) {
    return (
      <div key={"answer-" + index} className="flex flex-row">
        <div className="flex flex-row basis-2/3 justify-start items-center gap-3">
          <div className="px-2 py-1 rounded-lg bg-bg-grey border border-border-grey">
            <i className="ri-robot-2-fill text-primary text-3xl"></i>
          </div>
          <div className="flex text-tertiary p-4 rounded-r-xl rounded-bl-xl bg-bg-grey border border-border-grey items-center">
            {message}
          </div>
        </div>
      </div>
    );
  }

  function getHumanMessage(message, index) {
    return (
      <div key={"question-" + index} className="flex flex-row">
        <div className="basis-1/3"></div>
        <div className="flex flex-row basis-2/3 justify-end items-center gap-3">
          <div className="flex text-tertiary py-3 px-5 rounded-l-xl rounded-tr-xl bg-primary text-white items-center text-sm">
            {message}
          </div>
        </div>
      </div>
    );
  }

  function getChatComponent() {
    return state.chatHistory.map(function (record, index) {
      let humanMessage, aiMessage;
      if (record.question) {
        humanMessage = getHumanMessage(record.question, index);
      }
      if (record.answer) {
        aiMessage = getAIMessage(record.answer, index);
      }
      return (
        <>
          {humanMessage}
          {aiMessage}
        </>
      );
    });
  }

  function handleKeyDown(event) {
    if (event.key === "Enter" && !state.isLoading) {
      askQuestion();
    }
  }

  function scrollToBottom() {
    const element = document.getElementById("scrollable-parent");
    element.scrollTop = element.scrollHeight;
  }

  const chatComponent = getChatComponent();

  return (
    <div
      className="flex flex-col grow"
      style={{ height: "700px" }}
    >
      <div className="flex flex-row grow gap-4">
        <div className="basis-1/2 bg-white border-bg-grey rounded border">
          <ProblemStatement
            setProblemStatement={setProblemStatement}
            statement={state.statement}
            enableModelSettings={false}
            enableSubmit={false}
          />
        </div>
        <div className="flex flex-col basis-1/2 bg-white border-bg-grey rounded border">
          <OutputHeader
            header="Chat"
            description="Chat with our AI assistant and find answers related to your data"
            handleClearing={handleClearing}
            enableClearing={true}
            enableMetadataSwitch
            handleMetadataSwitchChange={() =>
              setMetadataSwitchToggle(!metadataSwitchToggle)
            }
            metadataSwitchValue={metadataSwitchToggle}
          />
          <div className="flex flex-col grow gap-3 p-4 max-h-[calc(100vh-330px)] h-[100%] w-[100%]">
            <div
              className="flex flex-col bg-bg-grey max-h-[calc(100vh-350px)] h-[100%] w-[100%] p-4 gap-3 overflow-scroll"
              id="scrollable-parent"
            >
              {chatComponent}
              {state.isLoading && (
                <div className="flex flex-col justify-center items-center">
                  <img src={Loader} className="w-[50px]" alt="loading" />
                </div>
              )}
            </div>
            <div className="flex flex-row items-center p-4 bg-white gap-2 rounded-md border-bg-grey border">
              <div className="flex grow">
                <input
                  className="w-[100%] text-sm text-tertiary rounded-full py-2 px-3 border border-border-grey focus:border-border-grey"
                  type="text"
                  placeholder="Type a message..."
                  value={state.currentQuestion}
                  onChange={(e) => handleCurrentQuestionChange(e.target.value)}
                  onKeyDown={handleKeyDown}
                  disabled={state.isLoading}
                />
              </div>
              <i className="ri-mic-line text-xl text-tertiary"></i>
              <CustomButton
                additionalStyle="px-2 py-1"
                rounded="rounded-full"
                onClick={askQuestion}
                disabled={state.isLoading || !state.currentQuestion}
              >
                <i className="ri-send-plane-fill text-white text-xl"></i>
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataProductQASection;
