import { useState } from "react";
import MUiTabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

export default function Tabs({ tabs, tabContent, shouldShowTabContent }) {
  const [value, setValue] = useState(0);
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 1 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabsElement = tabs.map((tab, index) => {
    return (
      <Tab
        key={"tab-" + index}
        label={tab}
        {...a11yProps(index)}
        sx={{
          fontWeight: "bold",
          "&.Mui-selected": {
            color: "#017A49",
          },
        }}
      />
    );
  });
  const tabContentElement = tabContent.map((content, index) => {
    return (
      <CustomTabPanel
        className="max-h-[calc(100vh-254px)] overflow-scroll-x overflow-scroll-y"
        key={"tab-panel-" + index}
        value={value}
        index={index}
      >
        {content}
      </CustomTabPanel>
    );
  });

  return (
    <>
      <MUiTabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="scrollable"
        TabIndicatorProps={{
          sx: {
            bgcolor: "#017A49",
            fontWeight: "700",
          },
        }}
      >
        {tabsElement}
      </MUiTabs>
      {shouldShowTabContent && tabContentElement}
    </>
  );
}
