
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";

function LoginSignUpLayout({ children }) {
  return (
    <div className="flex flex-row h-[100vh]">
      <ToastContainer />
      <div className="flex grow items-center justify-center">
        {children}
      </div>
    </div>
  )
}

export default LoginSignUpLayout