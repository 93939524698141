import { axiosInstance } from "../lib/axios";
import {
  ABAC_DATA_PRODUCT_SEARCH_V1_URL,
  ABAC_DATA_PRODUCT_USAGE_V1_URL,
  ABAC_DATA_PRODUCT_LINEAGE_V1_URL,
  QA_V1_URL,
  ABAC_DATA_PRODUCT_RUN_V1_URL,
  ABAC_DATA_PRODUCT_STATUS_V1_URL,
  ABACK_FEEDBACK_SUBMIT_V1_URL,
  DEMO_REFRESH_V1_URL,
} from "../lib/api-urls";
import { getSystemCd } from "../lib/local-storage";

async function search({ searchValue }) {
  const systemCd = getSystemCd();
  let payload = {
    searchCriteria: {
      query: searchValue,
    },
  };
  if (systemCd) {
    payload = {
      ...payload,
      sendingSystemCd: systemCd,
    };
  }
  try {
    return await axiosInstance.post(ABAC_DATA_PRODUCT_SEARCH_V1_URL, payload);
  } catch (e) {
    console.log(e);
    return e;
  }
}

async function getUsageData({ commonName }) {
  const systemCd = getSystemCd();
  let payload = {
    sendingSystemCd: systemCd,
    query: commonName,
  };

  try {
    return await axiosInstance.post(ABAC_DATA_PRODUCT_USAGE_V1_URL, payload);
  } catch (e) {
    console.log(e);
    return e;
  }
}

async function getLineageData({ fullName }) {
  const systemCd = getSystemCd();
  let payload = {
    sendingSystemCd: systemCd,
    fullName: fullName,
  };

  try {
    return await axiosInstance.post(ABAC_DATA_PRODUCT_LINEAGE_V1_URL, payload);
  } catch (e) {
    console.log(e);
    return e;
  }
}

async function runJobInDatabricks(jobID) {
  let payload = {
    job_id: Number(jobID),
  };

  try {
    return await axiosInstance.post(ABAC_DATA_PRODUCT_RUN_V1_URL, payload);
  } catch (e) {
    console.log(e);
    return e;
  }
}
async function jobStatusInDatabricks(jobID) {
  try {
    return await axiosInstance.get(
      ABAC_DATA_PRODUCT_STATUS_V1_URL(Number(jobID))
    );
  } catch (e) {
    console.log(e);
    return e;
  }
}

async function getCustomSummaryV1({
  chatHistory,
  document,
  question,
  metadata,
  session_id,
}) {
  try {
    const payload = {
      model_platform: "openai",
      model_temperature: 0,
      model_type: "chat",
      prompt_key: "NUDATA_QA",
      model_name: "gpt-4o",
      input_variables: {
        context: `
        [Metadata]: 
        "${JSON.stringify(metadata)}"

        [Dataset]:
        "${document}"
        `,
        question: question,
        chat_history: JSON.stringify(chatHistory),
      },
      session_id: sessionStorage.getItem("sessionId"),
    };
    return await axiosInstance.post(QA_V1_URL, payload);
  } catch (e) {
    return e;
  }
}

async function submitFeedbackForm(id, feedbacks) {
  try {
    const payload = {
      appSource: "NuData",
      id,
      feedbacks,
    };
    return await axiosInstance.put(ABACK_FEEDBACK_SUBMIT_V1_URL(id), payload);
  } catch (e) {
    return e;
  }
}

async function demoRefresh() {
  try {
    const payload = {
      appSource: "NuData",
      job_id: "345716258625663",
    };
    return await axiosInstance.post(DEMO_REFRESH_V1_URL, payload);
  } catch (e) {
    return e;
  }
}

export {
  search,
  getUsageData,
  getLineageData,
  getCustomSummaryV1,
  runJobInDatabricks,
  jobStatusInDatabricks,
  submitFeedbackForm,
  demoRefresh
};
