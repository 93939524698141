import DataBricksIcon from '../../assets/images/databricks.png'
import RedshiftIcon from '../../assets/images/redshift.png'
import S3Icon from '../../assets/images/s3.png'
import PostgresIcon from '../../assets/images/postgres.png'
import OracleIcon from '../../assets/images/oracle.png'
import MySQLIcon from '../../assets/images/mysql.png'
import PdfIcon from '../../assets/images/pdf.png'
import SuperSet from '../../assets/images/superset.png'

export default function DataProductSourceSystemIcon({ type }) {
  let image
  switch (true) {
    case /Databricks/.test(type):
      image = DataBricksIcon
      break
    case /Redshift/.test(type):
      image = RedshiftIcon
      break
    case /S3/.test(type):
      image = S3Icon
      break
    case /PostgreSQL/.test(type):
      image = PostgresIcon
      break
    case /Oracle/.test(type):
      image = OracleIcon
      break
    case /PDF/.test(type):
      image = PdfIcon
      break
    case /MySQL/.test(type):
      image = MySQLIcon
      break
    case /Superset/.test(type):
      image = SuperSet
      break
      default:
      image = DataBricksIcon
      break
  }
  return (
    <img className='w-[50px] max-w-none ' src={image} alt="Source System Icon" />
  )
}