import { toast as toastify } from 'react-toastify'

function toast({ message, duration = 2000 }) {
  console.log(message)
  toastify(message, {
    position: toastify.POSITION.TOP_RIGHT,
    autoClose: duration,
    className: 'rounded-md'
  })
}

export default toast