import axios from 'axios'
import { handleUnAuthError } from '../lib/helper'
import toast from '../lib/toast'
import { LOGIN_PAGE_PATH } from './paths';

//CRMC BE Axios Instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  undefined,
  function (error) {
    // Handle request errors here
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Modify the response data here (e.g., parse, transform)
    const { status, data } = response
    if (status === 204) {
      return {
        error: {
          status: 204,
          message: 'Null Response'
        }
      }
    }

    return { data };
  },
  function (error) {
    // Handle response errors here
    let errorCode = '', message = 'Something went wrong, please try again later'
    if (error.response) {
      const { status, data } = error.response
      errorCode = status
      message = data.detail
    }
    if (errorCode === 401) {
      if (error.request.responseURL !== process.env.REACT_APP_API_URL + LOGIN_PAGE_PATH) {
        toast({ message: 'Session Expired, redirecting to Login Page', duration: 2500 })
      }
      setTimeout(function () {
        handleUnAuthError()
      }, 2500)
      return Promise.reject({
        error: {
          errorCode,
          message: 'Unauthorized'
        }
      })
    } else {
      return Promise.reject({
        error: {
          errorCode,
          message
        }
      })
    }
  }
);




export { axiosInstance };