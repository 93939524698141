const LOGIN_URL = `/login`
const SIGNUP_URL = `/sign-up`
const ABAC_DATA_PRODUCT_SEARCH_V1_URL = `/nuscale/data/search/v1`
const ABAC_DATA_PRODUCT_USAGE_V1_URL = `/nuscale/ops/api/usage/v1`
const ABAC_DATA_PRODUCT_LINEAGE_V1_URL = `/nuscale/data/lineage/v1`
const ABAC_DATA_PRODUCT_RUN_V1_URL = `/nuscale/workflow/pipeline/run/v1`
const ABAC_DATA_PRODUCT_STATUS_V1_URL = (id) => (`/nuscale/workflow/pipeline/${id}/status/v1?appSource=NuData`)
const ABAC_WORKFLOW_SEARCH_V1_URL = `/nuscale/workflow/pipeline/search/v1`
const QA_V1_URL = `/nuscale/llm/agent/qa/v1`
const DEMO_REFRESH_V1_URL = `/nuscale/demo/refresh/v1`
const ABACK_FEEDBACK_SUBMIT_V1_URL = (id) => (`/nuscale/data/update/${id}/v1`)


export {
  LOGIN_URL,
  SIGNUP_URL,
  ABAC_DATA_PRODUCT_SEARCH_V1_URL,
  ABAC_DATA_PRODUCT_USAGE_V1_URL,
  ABAC_DATA_PRODUCT_LINEAGE_V1_URL,
  ABAC_DATA_PRODUCT_RUN_V1_URL,
  ABAC_DATA_PRODUCT_STATUS_V1_URL,
  ABAC_WORKFLOW_SEARCH_V1_URL,
  QA_V1_URL,
  ABACK_FEEDBACK_SUBMIT_V1_URL,
  DEMO_REFRESH_V1_URL
}