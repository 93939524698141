import React from "react";
import {
  Button,
  Chip,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import { runJobInDatabricks } from "../../../api/data-products";
import toast from "../../../lib/toast";
import moment from "moment";

const millisecondsToMinutesAndSecondsString = (milliseconds) => {
  const duration = moment.duration(milliseconds);
  const minutes = Math.floor(duration.asMinutes());
  const seconds = Math.floor(duration.seconds());

  return `${minutes}m ${seconds}s`;
};

const calculateComputeCost = (milliseconds) => {
  const duration = moment.duration(milliseconds);
  const minutes = Math.floor(duration.asMinutes() * 1.2);
  const seconds = Math.floor(duration.seconds() * 1.2);
  return `${minutes}.${seconds}`;
};

const DataProductRunSection = ({
  dataProductSource,
  details,
  refreshRunDetails,
}) => {
  const onJobRunClicked = async () => {
    const { data, error } = await runJobInDatabricks(dataProductSource?.jobID);
    if (error) {
      toast({ message: error.message });
    } else {
      const response_data = data.data;
      toast({
        message: `Job Triggered Successfully Run Id: ${response_data.run_id}`,
      });
    }
    refreshRunDetails();
  };

  return (
    <div style={{ padding: "5px" }}>
      <Grid container wrap="nowrap">
        <Grid item sx={{ flexGrow: 1 }}>
          <p>
            <strong>Job Id:</strong>{" "}
            <a
              href={`https://dbc-8702f8dc-dbfa.cloud.databricks.com/?o=5399533847894374#job/${dataProductSource?.jobID}`}
              target="_blank"
              rel="noopener noreferrer"
              className="styled-link"
            >
              {dataProductSource?.jobID}
            </a>
          </p>
          {details && (
            <>
              <p style={{ marginTop: 0 }}>
                <strong>Creator:</strong>{" "}
                {details?.jobDetails?.creator_user_name}
              </p>
              <p style={{ marginTop: 0 }}>
                <strong>Job Name:</strong> {details?.jobDetails?.settings?.name}
              </p>
              <p style={{ marginTop: 0 }}>
                <strong>Tags:</strong>{" "}
                {Object.entries(details?.jobDetails?.settings?.tags ?? [])
                  .map(([key, value]) => `${key} ${value}`.trim())
                  .join(", ")}
              </p>
            </>
          )}
          <div style={{ marginTop: "25px" }}>
            <Button
              variant="outlined"
              color="success"
              className="p-4 mx-5"
              onClick={onJobRunClicked}
            >
              Run Job
            </Button>
            <Button
              variant="outlined"
              color="success"
              className="p-4 mx-5"
              onClick={() => {
                refreshRunDetails();
              }}
              style={{
                color: "gray",
                border: "1px solid gray",
                marginLeft: "10px",
              }}
            >
              Refresh
            </Button>
          </div>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <p>
            <strong>Sharing</strong>
          </p>
          <List sx={{ p: 0 }}>
            {details?.jobPermissionDetails?.access_control_list.map(
              (entry, index) => (
                <ListItem key={index} sx={{ padding: "0px" }}>
                  {entry.user_name && (
                    <div>
                      <p>
                        <i class="ri-user-line"></i> {entry.display_name}
                        <Chip
                          size="small"
                          label={entry.all_permissions[0].permission_level}
                          color="primary"
                          style={{
                            color: "gray",
                            border: "1px solid gray",
                            marginLeft: "5px",
                          }}
                          variant="outlined"
                        />
                      </p>
                    </div>
                  )}
                  {entry.group_name && (
                    <div>
                      <p>
                        <i class="ri-group-line"></i> {entry.group_name}
                        <Chip
                          size="small"
                          label={entry.all_permissions[0].permission_level}
                          color="primary"
                          style={{
                            color: "gray",
                            border: "1px solid gray",
                            marginLeft: "5px",
                          }}
                          variant="outlined"
                        />
                      </p>
                    </div>
                  )}
                </ListItem>
              )
            )}
          </List>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          {details && (
            <>
              <p style={{ marginTop: 0 }}>
                <strong>Compute Details:</strong>
              </p>
              <p style={{ marginTop: 0 }}>
                <strong>&nbsp;Cluster Name:</strong>{" "}
                {details?.clusterDetails?.cluster_name}
              </p>
              <p style={{ marginTop: 0 }}>
                <strong>&nbsp;Cluster Id:</strong>{" "}
                {details?.clusterDetails?.cluster_id}
              </p>
              <p style={{ marginTop: 0 }}>
                <strong>&nbsp;Spark Version:</strong>{" "}
                {details?.clusterDetails?.spark_version}
              </p>
              <p style={{ marginTop: 0 }}>
                <strong>&nbsp;Node Type:</strong>{" "}
                {details?.clusterDetails?.node_type_id}
              </p>
              <p style={{ marginTop: 0 }}>
                <strong>&nbsp;Worker Nodes:</strong>{" "}
                {`${details?.clusterDetails?.autoscale.min_workers} - ${details?.clusterDetails?.autoscale.max_workers}`}
              </p>
              <p style={{ marginTop: 0 }}>
                <strong>&nbsp;Auto Termination:</strong>{" "}
                {`${details?.clusterDetails?.autotermination_minutes} m`}
              </p>
            </>
          )}
        </Grid>
        <Grid item sx={{ flexShrink: 1 }}>
          <Paper elevation={2} sx={{ p: 1 }}>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ textAlign: "center", color: "#CCCCCC" }}
            >
              Last Run Cost
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              className="text-center"
              sx={{ marginBottom: "10px", fontWeight: "600", color: "#017A49" }}
            >
              {details?.runDetails?.runs[0]?.run_duration
                ? `$${calculateComputeCost(
                    details?.runDetails?.runs[0]?.run_duration
                  )}`
                : "N/A"}
            </Typography>
          </Paper>
          <Paper elevation={2} sx={{ p: 1, mt: 1 }}>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ textAlign: "center", color: "#CCCCCC" }}
            >
              Total Cost Of Ownership
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              className="text-center"
              sx={{ marginBottom: "10px", fontWeight: "600", color: "#017A49" }}
            >
              {details?.runDetails?.total_run_duration
                ? `$${calculateComputeCost(
                    details?.runDetails?.total_run_duration
                  )}`
                : "N/A"}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <br />
      <br />
      {details && (
        <div
          className="mt-1"
          style={{ height: "450px", overflowY: "scroll", width: "100%" }}
        >
          <table
            style={{
              width: "100%",
              border: "1px solid",
              borderColor: "grey.400",
            }}
          >
            <thead>
              <tr>
                <th className="border border-gray-400 p-2 font-bold uppercase text-black">
                  Run ID
                </th>
                <th className="border border-gray-400 p-2 font-bold uppercase text-black">
                  Execution Time
                </th>
                <th className="border border-gray-400 p-2 font-bold uppercase text-black">
                  Duration
                </th>
                <th className="border border-gray-400 p-2 font-bold uppercase text-black">
                  Status
                </th>
                <th className="border border-gray-400 p-2 font-bold uppercase text-black">
                  Description
                </th>
                <th className="border border-gray-400 p-2 font-bold uppercase text-black">
                  Cost
                </th>
                <th className="border border-gray-400 p-2 font-bold uppercase text-black">
                  Version ID
                </th>
              </tr>
            </thead>
            <tbody>
              {details?.runDetails?.runs?.map((run) => (
                <tr key={run.run_id}>
                  <td className="border border-gray-400 p-2 text-center">
                    <a
                      href={run.run_page_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="styled-link"
                    >
                      {run.run_id}
                    </a>
                  </td>
                  <td className="border border-gray-400 p-2 text-center">
                    {run?.end_time
                      ? moment(new Date(run?.end_time)).format(
                          "MMMM D, YYYY h:mm A"
                        )
                      : "N/A"}
                  </td>
                  <td className="border border-gray-400 p-2 text-center">
                    {millisecondsToMinutesAndSecondsString(run.run_duration)}
                  </td>
                  <td className="border border-gray-400 p-2 text-center">
                    {run?.state?.result_state === "SUCCESS" ? (
                      <Chip
                        label="SUCCESS"
                        color="success"
                        variant="outlined"
                      />
                    ) : (
                      <Chip
                        label={
                          run?.state.result_state ??
                          run?.state?.life_cycle_state ??
                          "N/A"
                        }
                        color="primary"
                        style={{ color: "gray", border: "1px solid gray" }}
                        variant="outlined"
                      />
                    )}
                  </td>
                  <td className="border border-gray-400 p-2 text-center">
                    {run.state.state_message}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {`$${calculateComputeCost(run?.run_duration)}`}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {run?.version_id}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DataProductRunSection;
