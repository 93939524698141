import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function CustomSwitch({ handleSwitchChange, switchValue }) {
  return (
    <ToggleButtonGroup size="small" exclusive={true} value={switchValue} onChange={handleSwitchChange} aria-label="Small sizes">
      <ToggleButton value={0} key="detailed">
        <i className="ri-align-left text-xl"></i>
      </ToggleButton>
      <ToggleButton value={1} key="bulleted">
      <i className="ri-list-check text-xl"></i>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default CustomSwitch;