import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  AppBar,
  Backdrop,
  Badge,
  Box,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import NuscaleLogo from "../../assets/images/nuscale-logo.svg";
import { HOME_URL } from "../../auth/config";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import CodeIcon from "@mui/icons-material/Code";
import Dropdown from "../dropdown/Dropdown";
import { handleLogout } from "../../auth/auth";
import SettingsIcon from "@mui/icons-material/Settings";
import RefreshIcon from "@mui/icons-material/Refresh";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { demoRefresh } from "../../api/data-products";

const Header = () => {
    const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  return (
    <AppBar position="sticky" sx={{ bgcolor: "white" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 4,
          color: "text.tertiary",
        }}
      >
        <Box>
          <Link to={HOME_URL} style={{}}>
            <img
              src={NuscaleLogo}
              alt="nuscale-logo"
              style={{ width: "150px", marginRight: "10px" }}
            />
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            height: "48px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                window.location.reload();
              }}
            >
              <RefreshIcon />
            </IconButton>
            <Link
              to="https://apps.nuscale.io:3000/d/fdimwyvlnnk00f/nucentral-api-monitoring?orgId=1"
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none", marginLeft: "5px" }}
            >
              <IconButton>
                <DataUsageIcon />
              </IconButton>
            </Link>
            <Link
              to="https://apps.nuscale.io:8001/docs"
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none", marginLeft: "5px" }}
            >
              <IconButton>
                <CodeIcon />
              </IconButton>
            </Link>
            <Link
              to="https://www.nuscale.ai/contact"
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <IconButton>
                <SmartphoneIcon fontSize="small" />
              </IconButton>
            </Link>
            <IconButton>
              <Badge color="error" variant="dot">
                <NotificationsNoneOutlinedIcon />
              </Badge>
            </IconButton>
          </Box>
          <Box
            sx={{
              ml: 2,
              paddingLeft: "20px",
              borderLeft: "thin solid green",
            }}
          >
            <Dropdown
              menuComponent={<SettingsIcon />}
              dropdownItems={[
                {
                  component: (
                    <Typography variant="body2" color={"info"}>
                      Profile
                    </Typography>
                  ),
                  handler: () => {},
                },
                {
                  component: (
                    <Typography variant="body2" color={"info"}>
                      Refresh Demo
                    </Typography>
                  ),
                  handler: async () => {
                    sessionStorage.removeItem("sessionId");
                    const session_id = uuidv4();
                    sessionStorage.setItem("sessionId", session_id);
                    setIsLoaderOpen(true);
                    await demoRefresh();
                    setIsLoaderOpen(false);
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                  },
                },
                {
                  component: (
                    <Typography variant="body2" color={"info"}>
                      Logout
                    </Typography>
                  ),
                  handler: handleLogout,
                },
              ]}
              showArrow={false}
            />
          </Box>
        </Box>
      </Toolbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoaderOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AppBar>
  );
};

export default Header;
