import DataProductContentCard from "../DataProductContentCard";
import 'react-json-view-lite/dist/index.css';

export default function DataProductsSection({ dataProducts, openDataProductDetails, selectedDataProduct }) {

  const dataProductContentCards = dataProducts.map(
    (dataProduct) => {
      if (selectedDataProduct && dataProduct.uuid === selectedDataProduct.uuid) {
        return <DataProductContentCard
          dataProduct={dataProduct}
          openDataProductDetails={openDataProductDetails}
          key={dataProduct.uuid}
          selected={true}
        />
      }
      return <DataProductContentCard
        dataProduct={dataProduct}
        openDataProductDetails={openDataProductDetails}
        key={dataProduct.uuid}
      />
    }
  )

  const noDataProductsElement = (
    <div className='flex justify-center'>No Documents Available</div>
  )

  return (
    <div className="grid grid-col-1 grid-flow-row gap-1">
      {dataProducts.length > 0 && dataProductContentCards}
      {dataProducts.length === 0 && noDataProductsElement}
    </div>
  )
}