
function PageDescription({ name }) {
  return (
    <div className="flex flex-row gap-1 px-5 py-3">
      <div className="text-secondary hover:cursor focus:cursor">
        {/* <CustomLink to={HOME_PAGE_PATH}>
          <i className="ri-arrow-left-s-line text-base"></i>
          <span className="text-sm text-bold">Home</span>
        </CustomLink> */}
      </div>
      <div className="flex grow text-3xl font-bold justify-center">
        {name}
      </div>
    </div>
  )
}

export default PageDescription