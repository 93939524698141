import { axiosInstance } from '../lib/axios';
import { ABAC_WORKFLOW_SEARCH_V1_URL } from '../lib/api-urls'

async function search({ searchValue }) {
  let payload = {
    "searchCriteria": {
      "query": {
        "_id": searchValue
      }
    }
  }

  try {
    return await axiosInstance.post(ABAC_WORKFLOW_SEARCH_V1_URL, payload, {
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD
      }
    })
  } catch (e) {
    console.log(e)
    return e
  }
}

export { search }