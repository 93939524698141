const Users = {
  [process.env.REACT_APP_USER1_USERNAME]: {
    username: process.env.REACT_APP_USER1_USERNAME,
    password: process.env.REACT_APP_USER1_PASSWORD,
    fullName: process.env.REACT_APP_USER1_USERNAME,
    systemCd: 'S01'
  },
  [process.env.REACT_APP_USER2_USERNAME]: {
    username: process.env.REACT_APP_USER2_USERNAME,
    password: process.env.REACT_APP_USER2_PASSWORD,
    fullName: process.env.REACT_APP_USER2_USERNAME,
    systemCd: 'S02'
  },
  [process.env.REACT_APP_USER3_USERNAME]: {
    username: process.env.REACT_APP_USER3_USERNAME,
    password: process.env.REACT_APP_USER3_PASSWORD,
    fullName: process.env.REACT_APP_USER3_USERNAME,
    systemCd: '*'
  }
}


export default Users