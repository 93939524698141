import moment from "moment";
import DataProductSourceSystemIcon from "../DataProductSourceSystemIcon";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function DataProductContentCard({
  dataProduct,
  openDataProductDetails,
  selected = false,
}) {
  let selectedContentCardCss = "";
  if (selected) {
    selectedContentCardCss = "bg-lightblue";
  }
  return (
    <>
      <div
        className={`flex flex-col border border-grey rounded-md px-1 pt-1 gap-1 cursor-pointer ${selectedContentCardCss}`}
        onClick={() => openDataProductDetails({ dataProduct })}
      >
        <div className="flex flex-row gap-3 items-center">
          <div className="flex items-center">
            <DataProductSourceSystemIcon
              type={dataProduct._source?.sourceSystem}
            />
          </div>
          <div className="flex flex-col">
            <div className="text-xs">
              <span style={{ fontWeight: "bold" }}>Tags: </span>
              <span>{dataProduct._source?.locationTag}</span>
            </div>

            <div className=" text-xs">
              <div>
                <span style={{ fontWeight: "bold" }}>Domain: </span>
                <span>{dataProduct._source?.dataDomain}</span>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Version: </span>
                <span>{dataProduct._source?.versionID}</span>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Freshness: </span>
                <span>
                  {moment(dataProduct._source?.insertedAt || "").fromNow()}
                  {dataProduct._source?.pointOfContact && (
                    <>
                      <span className="font-bold">{" by "}</span>
                      {dataProduct._source?.pointOfContact}
                    </>
                  )}
                </span>
              </div>
            </div>
            <div className="text-base text-primary mb-1">
              {dataProduct._source?.commonName}
              <span>
                <Tooltip
                  title={dataProduct._source?.description}
                  sx={{ ml: 1, mt: -0.5 }}
                >
                  <InfoOutlinedIcon fontSize="small" color="primary" />
                </Tooltip>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
