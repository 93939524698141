const LOGIN_PAGE_PATH = "/login"
const SIGNUP_PAGE_PATH = "/sign-up"
const HOME_PAGE_PATH = "/home"
const SEARCH_PAGE_PATH = "/search"
const DATA_RETRIEVAL_PAGE_PATH = "/data-retrieval"
const DATA_INGESTION_PAGE_PATH = "/data-ingestion"
const LOGOUT_PAGE_PATH = "/logout"
const BASE_PATH = "/"

export {
  LOGIN_PAGE_PATH,
  SIGNUP_PAGE_PATH,
  HOME_PAGE_PATH,
  SEARCH_PAGE_PATH,
  DATA_RETRIEVAL_PAGE_PATH,
  DATA_INGESTION_PAGE_PATH,
  LOGOUT_PAGE_PATH,
  BASE_PATH
}