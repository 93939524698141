import React from 'react'
import Header from '../header/Header'
import { ToastContainer } from 'react-toastify'
import { Container, CssBaseline } from '@mui/material'

const AuthenticatedLayout = ({ children }) => {
  return (
    <div>
      <CssBaseline />
      <Header />
      <Container
        style={{
          padding: 0
        }}
        maxWidth={"100%"}
      >
        {children}
      </Container>
      <ToastContainer />
    </div>
  )
}

export default AuthenticatedLayout