import {
  DATA_INGESTION_PAGE_PATH,
  DATA_RETRIEVAL_PAGE_PATH,
  SEARCH_PAGE_PATH,
} from "../lib/paths";
import DataDiscovery from "../assets/images/data-discovery.png";
import DataIngestion from "../assets/images/data-ingestion.png";
import DataCollection from "../assets/images/data-collection.png";

function getModels() {
  return new Promise(function (resolve, reject) {
    resolve([
      {
        name: "Data Mesh",
        list: [
          {
            name: "Search & Discover",
            icon: DataDiscovery,
            alt: "Search & Discover",
            path: SEARCH_PAGE_PATH,
            description: "Search and Discovery Data Products",
            enabled: true,
          },
          {
            name: "Ingest Data",
            icon: DataIngestion,
            alt: "Ingest Data",
            path: DATA_INGESTION_PAGE_PATH,
            description: "API enabled Data Ingestion",
            enabled: true,
          },
          {
            name: "Retrieve Data",
            icon: DataCollection,
            path: DATA_RETRIEVAL_PAGE_PATH,
            description: "API enabled Data Retrieval",
            enabled: true,
          },
        ],
      },
    ]);
  });
}

function getDefaultProblemStatement(name) {
  return new Promise(function (resolve) {
    resolve({
      data: {
        defaultStatement: csvData[name] ?? "",
      },
    });
  });
}

export { getModels, getDefaultProblemStatement };

const csvData = {
  "comprehensive_personnel_analysis.csv": `Personnel_ID,Name,Rank,NumberOfDeployments,FitnessScore,Training_Name,Score,RankInTraining
  P0002,Denise Charles MD,Pvt,5,252,seize user-centric communities,87,1
  P0003,Leroy Phillips,Pvt,3,208,maximize integrated eyeballs,97,1
  P0006,Taylor Steele,Pvt,3,246,exploit best-of-breed e-markets,70,1
  P0009,Stacy Campbell,Pvt,0,205,integrate open-source e-commerce,89,1
  P0011,Donald Booth,Pvt,1,275,deliver integrated applications,82,1
  P0013,Tiffany Jones MD,Pvt,2,262,grow virtual paradigms,88,1
  P0015,Roberto Hill,Pvt,1,252,productize bleeding-edge eyeballs,77,1
  P0016,Bruce Holland,Pvt,0,277,unleash ubiquitous solutions,74,1
  P0018,Rebecca Harrington,Pvt,3,251,synergize viral deliverables,83,1
  P0019,Stephen Greer,Pvt,3,284,matrix virtual supply-chains,76,1
  P0021,Michael Ward,Pvt,5,257,embrace granular partnerships,89,1
  P0023,Tyrone Carroll,Pvt,4,257,harness plug-and-play schemas,100,1
  P0024,Madison Pena,Pvt,0,200,unleash killer markets,75,1
  P0027,Angela Porter,Pvt,4,219,re-intermediate magnetic metrics,94,1
  P0028,Craig Martin,Pvt,5,256,deploy intuitive e-tailers,72,1
  P0030,Sandra Rivas,Pvt,3,295,re-contextualize best-of-breed vortals,93,1
  P0031,Curtis Reilly,Pvt,3,282,benchmark efficient infrastructures,95,1
  P0033,Alicia Stevenson,Pvt,0,275,expedite bricks-and-clicks solutions,91,1
  P0036,Dr. Timothy Roberts MD,Pvt,1,271,e-enable impactful web-readiness,97,1
  P0037,Danny Powers,Pvt,1,243,deploy revolutionary e-business,100,1
  P0038,Eric Delacruz,Pvt,1,255,morph mission-critical content,72,1
  P0039,Stephen Gallegos,Pvt,4,282,synthesize transparent functionalities,92,1
  P0040,Christopher Hall,Pvt,3,228,evolve frictionless e-commerce,80,1
  P0041,Manuel Simpson,Pvt,4,276,integrate magnetic action-items,74,1
  P0044,Travis King,Pvt,5,237,brand viral markets,73,1
  P0046,Eric Griffin,Pvt,5,244,engineer customized initiatives,97,1
  P0049,Cameron Knapp,Pvt,1,213,engineer distributed users,90,1
  P0055,Levi Rivera,Pvt,2,224,redefine web-enabled info-mediaries,89,1
  P0057,Nicholas Larson,Pvt,3,244,empower plug-and-play technologies,96,1
  P0059,Crystal Holland,Pvt,0,287,productize ubiquitous deliverables,84,1
  P0060,Tammy Edwards,Pvt,1,208,target distributed eyeballs,72,1
  P0061,Ricardo Soto,Pvt,2,293,architect user-centric initiatives,91,1
  P0062,Ryan Hartman,Pvt,0,239,re-contextualize transparent action-items,87,1
  P0063,Tricia Mcclain,Pvt,0,250,seize rich bandwidth,70,1
  P0066,Thomas Shaffer,Pvt,4,287,empower cutting-edge action-items,78,1
  P0067,Lucas Peterson,Pvt,5,247,evolve viral metrics,77,1
  P0068,Christine Cox,Pvt,2,284,drive enterprise action-items,77,1
  P0072,Joshua Cordova,Pvt,2,238,benchmark integrated systems,72,1
  P0074,Rebecca Perry,Pvt,0,271,facilitate global content,94,1
  P0075,Tracy Goodwin,Pvt,1,212,integrate frictionless ROI,79,1
  P0076,Eric Cantu,Pvt,0,296,extend granular convergence,90,1
  P0078,Jose Walker,Pvt,3,280,deploy value-added systems,80,1
  P0079,Krista Rogers,Pvt,1,239,innovate distributed e-commerce,95,1
  P0082,Monique Atkinson DVM,Pvt,0,225,utilize interactive paradigms,72,1
  P0083,Allen Chan,Pvt,5,296,redefine web-enabled e-tailers,85,1
  P0085,Barbara Horne,Pvt,3,208,reinvent mission-critical eyeballs,82,1
  P0087,Rita Nicholson,Pvt,4,273,architect cross-media experiences,78,1
  P0088,Kathy Jennings,Pvt,0,206,repurpose one-to-one interfaces,99,1
  P0089,Ronald Figueroa,Pvt,4,250,visualize extensible supply-chains,83,1
  P0092,Elizabeth Delacruz,Pvt,5,243,target e-business experiences,81,1
  P0093,Joseph Vasquez,Pvt,5,267,monetize B2C e-commerce,97,1
  P0094,Russell Stone,Pvt,3,213,synergize vertical web services,85,1
  P0096,Heather Ayala,Pvt,2,224,enhance ubiquitous e-tailers,94,1
  P0097,Jordan George,Pvt,1,281,embrace rich technologies,71,1
  P0102,Diana Hawkins,Cpl,0,210,grow vertical communities,76,1
  P0112,Mark Ford,Cpl,4,221,target e-business models,74,1
  P0118,Carrie Richardson,Cpl,3,235,implement intuitive solutions,94,1
  P0120,Christina Farley,Cpl,1,268,enhance world-class e-tailers,96,1
  P0121,Heather Byrd,Cpl,3,217,drive user-centric info-mediaries,95,1
  P0122,Danny Klein,Cpl,2,272,redefine real-time convergence,84,1
  P0124,Jamie Luna,Cpl,1,248,integrate e-business e-services,97,1
  P0131,Francisco James,Cpl,0,224,exploit 24/7 systems,79,1
  P0134,Carrie Mata,Cpl,3,267,syndicate next-generation e-markets,71,1
  P0135,Regina Welch,Cpl,5,227,e-enable world-class e-tailers,73,1
  P0137,Debbie Solomon,Cpl,0,203,extend intuitive content,99,1
  P0138,Jay Marshall,Cpl,4,237,engineer collaborative channels,92,1
  P0140,Stacey Sexton,Cpl,5,233,brand frictionless e-services,96,1
  P0141,Jeremy Olson,Cpl,3,203,revolutionize out-of-the-box platforms,95,1
  P0142,Stephanie Rosario,Cpl,1,280,matrix impactful relationships,90,1
  P0144,Melissa Flores,Cpl,5,297,productize transparent interfaces,97,1
  P0145,Tara Noble,Cpl,0,205,implement B2B platforms,77,1
  P0147,Justin Martin,Cpl,5,229,morph enterprise interfaces,89,1
  P0148,John Butler,Cpl,3,226,deploy efficient portals,98,1
  P0149,Carl Montgomery,Cpl,0,241,generate turn-key info-mediaries,77,1
  P0151,Colleen Nelson,Cpl,1,214,exploit turn-key solutions,77,1
  P0154,Amy Abbott,Cpl,0,296,target next-generation metrics,74,1
  P0155,Ruben Fox,Cpl,1,251,orchestrate extensible convergence,85,1
  P0158,Brady Pugh,Cpl,3,288,enable synergistic web services,76,1
  P0159,Andre Moore,Cpl,4,228,iterate synergistic methodologies,82,1
  P0160,Bridget Smith,Cpl,5,216,re-contextualize innovative mindshare,79,1
  P0162,Nathaniel Washington,Cpl,1,278,unleash global functionalities,87,1
  P0164,Diane Park,Cpl,4,214,enhance front-end mindshare,90,1
  P0165,Brooke Munoz,Cpl,5,225,enhance efficient content,80,1
  P0167,Jeffrey Fry,Cpl,5,219,drive web-enabled infrastructures,70,1
  P0168,Carla Harris,Cpl,0,213,repurpose seamless functionalities,82,1
  P0169,Krista Wilson,Cpl,4,210,e-enable intuitive methodologies,72,1
  P0170,Timothy Ochoa,Cpl,1,210,visualize cross-media mindshare,98,1
  P0171,Jennifer Hatfield,Cpl,1,237,syndicate mission-critical schemas,93,1
  P0172,Maria Herman,Cpl,1,226,innovate cutting-edge interfaces,92,1
  P0174,Kenneth Rodriguez,Cpl,4,284,engage collaborative communities,78,1
  P0176,Jeremy Beard,Cpl,5,208,target innovative applications,84,1
  P0178,Daniel Rivas,Cpl,3,255,brand clicks-and-mortar web-readiness,77,1
  P0180,Sophia Powell,Sgt,0,219,target cross-media systems,75,1
  P0183,Max Bush,Sgt,2,285,re-intermediate transparent partnerships,95,1
  P0185,Philip Perez,Sgt,5,289,generate killer synergies,96,1
  P0190,Donna Sutton,Sgt,1,286,harness B2C relationships,80,1
  P0192,Theodore Hall,Sgt,1,299,synthesize plug-and-play markets,90,1
  P0193,Corey Sanders,Sgt,5,267,target cross-media applications,70,1
  P0196,Joyce Knight,Sgt,1,298,scale turn-key methodologies,91,1
  P0199,Steven Burton,Sgt,5,291,synthesize proactive web-readiness,96,1
  P0202,Sydney Meza,Sgt,4,212,repurpose B2C functionalities,89,1
  P0203,Jaclyn Osborne,Sgt,4,293,integrate granular convergence,91,1
  P0204,Angela Rhodes,Sgt,1,265,leverage customized methodologies,90,1
  P0205,Dennis Wyatt,Sgt,0,213,e-enable one-to-one deliverables,81,1
  P0206,Randy Sanders,Sgt,5,244,embrace value-added methodologies,77,1
  P0214,Nichole Schwartz,Sgt,2,296,synthesize holistic interfaces,71,1
  P0215,Tammy Salinas,Sgt,0,250,redefine proactive systems,95,1
  P0216,Adriana Hendricks,Sgt,3,246,reinvent seamless interfaces,78,1
  P0221,Ronald Wright,Sgt,5,246,synergize end-to-end ROI,84,1
  P0222,Claudia Mitchell,Sgt,2,254,drive end-to-end bandwidth,84,1
  P0226,Jeremy Levy,Sgt,2,276,incubate next-generation communities,81,1
  P0229,Mr. Timothy Morales MD,Sgt,3,248,reinvent plug-and-play ROI,96,1
  P0230,Levi George,Sgt,4,230,extend viral portals,95,1
  P0231,Joel Byrd,Sgt,2,294,embrace holistic supply-chains,98,1
  P0233,Desiree Browning,Sgt,2,251,transition visionary channels,90,1
  P0235,Meredith Stone,Sgt,5,206,target revolutionary info-mediaries,78,1
  P0238,Kimberly Vincent MD,Sgt,2,215,engage 24/365 solutions,83,1
  P0239,Shannon Scott,Sgt,0,245,target visionary channels,72,1
  P0241,Spencer Barnes,Sgt,4,247,enhance 24/7 deliverables,83,1
  P0242,Jasmine Chan,Sgt,3,222,cultivate collaborative relationships,84,1
  P0244,Tammy Benson,Sgt,2,250,incentivize leading-edge architectures,97,1
  P0245,Cassie Murphy,Sgt,3,254,morph web-enabled communities,70,1
  P0246,Courtney Weeks,Sgt,5,220,morph efficient platforms,93,1
  P0247,Carolyn Cole,Sgt,1,250,optimize clicks-and-mortar metrics,73,1
  P0248,Alexander Hernandez,Sgt,3,246,scale sticky e-services,89,1
  P0252,Angela George,Lt,5,299,enable sticky interfaces,75,1
  P0253,Nicholas Weeks,Lt,4,299,visualize impactful e-tailers,78,1
  P0254,Tammy Hebert,Lt,1,233,implement clicks-and-mortar e-markets,96,1
  P0256,Wesley Velasquez,Lt,4,260,brand dot-com applications,83,1
  P0257,David Brooks,Lt,1,227,implement cutting-edge architectures,76,1
  P0258,Julie Horton,Lt,4,221,brand viral schemas,84,1
  P0263,Ashley Howell,Lt,3,211,reinvent impactful bandwidth,71,1
  P0265,Daniel Mora,Lt,4,234,deploy collaborative convergence,83,1
  P0267,Karen Walters,Lt,2,231,implement bricks-and-clicks infrastructures,75,1
  P0268,Barbara Houston,Lt,4,223,re-intermediate visionary functionalities,98,1
  P0269,Andrew Kim,Lt,3,291,target plug-and-play channels,89,1
  P0270,Eric Bauer,Lt,0,275,facilitate cutting-edge deliverables,81,1
  P0271,Luis Hammond,Lt,1,203,transition seamless deliverables,80,1
  P0274,Jasmine Thomas,Lt,0,295,evolve strategic e-tailers,78,1
  P0277,Tammy Collins,Lt,5,228,transition wireless convergence,71,1
  P0279,Crystal Bradshaw,Lt,4,269,streamline e-business schemas,76,1
  P0282,Melody Black,Lt,3,248,unleash granular paradigms,85,1
  P0285,Michael Wyatt,Lt,3,283,implement plug-and-play applications,78,1
  P0287,Natalie Perry,Lt,5,235,maximize customized niches,73,1
  P0292,Karen Schaefer,Lt,3,299,maximize enterprise solutions,73,1
  P0293,Andrew Miller Jr.,Lt,5,291,unleash cutting-edge markets,97,1
  P0294,Judy Howard,Lt,3,263,e-enable web-enabled niches,95,1
  P0295,Brenda Dominguez,Lt,3,287,aggregate 24/7 convergence,88,1
  P0296,Jorge Kelly,Lt,4,289,morph revolutionary e-business,82,1
  P0297,Mary Abbott,Lt,0,247,morph virtual methodologies,97,1
  P0298,Jeremiah Camacho,Lt,3,219,extend efficient paradigms,88,1
  P0300,Michael Downs,Lt,5,277,deploy world-class info-mediaries,71,1
  P0302,Jennifer Floyd,Lt,4,300,cultivate leading-edge users,100,1
  P0303,Amy Small,Lt,5,297,orchestrate web-enabled experiences,82,1
  P0307,Crystal Webb,Capt,0,278,evolve open-source communities,86,1
  P0308,Michael Velez,Capt,4,258,mesh integrated e-tailers,90,1
  P0312,Heather Wilson,Capt,1,286,brand frictionless e-markets,71,1
  P0314,Alicia Lindsey,Capt,3,236,mesh visionary web services,90,1
  P0316,Krista Crawford,Capt,3,212,seize end-to-end technologies,89,1
  P0317,Randy Cortez,Capt,5,221,synergize robust networks,77,1
  P0318,Kimberly Peters,Capt,2,298,streamline vertical interfaces,95,1
  P0328,Karen Phillips,Capt,0,201,orchestrate one-to-one e-commerce,81,1
  P0331,George Norman,Capt,0,200,facilitate sticky portals,99,1
  P0335,Joann Davis,Capt,0,267,engage open-source initiatives,92,1
  P0336,Timothy Nelson,Capt,2,273,re-contextualize synergistic niches,96,1
  P0339,Johnny Murphy,Capt,3,283,extend compelling markets,79,1
  P0340,Alyssa Daugherty,Capt,4,232,harness intuitive partnerships,94,1
  P0341,Chase Lane,Capt,5,241,disintermediate dot-com action-items,86,1
  P0342,Amber Morris,Capt,3,232,re-intermediate rich synergies,76,1
  P0343,John Humphrey,Capt,1,264,iterate bleeding-edge architectures,96,1
  P0347,Megan Lewis,Capt,1,273,incentivize bricks-and-clicks eyeballs,90,1
  P0348,Justin Juarez,Capt,2,257,strategize virtual methodologies,99,1
  P0350,Cindy Patterson,Capt,0,248,iterate granular portals,71,1
  P0353,Mrs. Jessica Rivera,Capt,0,207,e-enable seamless architectures,81,1
  P0358,Richard Hopkins,Capt,4,252,aggregate 24/365 interfaces,95,1
  P0360,April Bowen,Capt,1,280,visualize collaborative platforms,82,1
  P0361,Jennifer Frederick,Capt,3,265,leverage 24/365 web-readiness,92,1
  P0362,Mr. Dean Winters,Major,3,267,integrate sticky experiences,72,1
  P0364,Ryan Mullen,Major,5,219,generate synergistic e-business,91,1
  P0369,Frederick Gordon,Major,5,254,architect bricks-and-clicks interfaces,81,1
  P0370,Gary Contreras,Major,4,285,reinvent revolutionary methodologies,93,1
  P0372,Tiffany Parker,Major,4,294,repurpose front-end infrastructures,71,1
  P0374,Alex Mills,Major,4,224,target turn-key web-readiness,72,1
  P0375,Tamara Mcknight,Major,5,208,productize impactful web-readiness,80,1
  P0376,Mr. Jeffrey Maldonado,Major,0,259,redefine dynamic ROI,85,1
  P0377,Daniel Kramer,Major,0,274,orchestrate cross-media e-business,100,1
  P0378,Kelly Patterson,Major,0,278,disintermediate customized solutions,96,1
  P0380,Brian Barry,Major,2,208,transform rich eyeballs,71,1
  P0381,Jerry Lewis,Major,1,293,scale best-of-breed platforms,89,1
  P0385,John Henderson,Major,5,214,revolutionize back-end models,89,1
  P0386,Michael Mooney,Major,4,235,target B2C web-readiness,73,1
  P0390,Maria Burnett,Major,0,212,innovate intuitive eyeballs,94,1
  P0391,Megan Waller,Major,3,278,reinvent world-class architectures,89,1
  P0392,Brian Grant,Major,3,205,synthesize seamless solutions,83,1
  P0393,Cheryl Diaz,Major,5,235,aggregate collaborative e-business,85,1
  P0395,Derek Watkins,Major,1,277,productize mission-critical partnerships,74,1
  P0397,Donald Collier,Major,1,222,evolve bricks-and-clicks e-tailers,92,1
  P0399,Lisa Daniels,Major,2,259,iterate proactive solutions,77,1
  P0400,Scott Monroe,Major,2,288,integrate transparent experiences,73,1
  P0401,Jane Duran,Major,1,255,re-intermediate clicks-and-mortar vortals,96,1
  `,
  "equipment_readiness_maintenance.csv": `Equipment_ID,Equipment_Type,Equipment_Status,Personnel_ID,Name,Rank,AvgFitnessScore
  E0000,M9 Pistol,Out of Service,P0000,Melissa Hardin,Pvt,232
  E0001,M11,Needs Maintenance,P0001,Jennifer Black,Pvt,207
  E0002,"M17, M18 MHS",Needs Maintenance,P0002,Denise Charles MD,Pvt,252
  E0003,M1911,Operational,P0003,Leroy Phillips,Pvt,208
  E0004,M1903 Springfield,Needs Maintenance,P0004,Keith Clayton,Pvt,222
  E0005,M1 Garand,Out of Service,P0005,Stacy Jacobs,Pvt,282
  E0006,M4 carbine/M4A1,Needs Maintenance,P0006,Taylor Steele,Pvt,246
  E0007,M16A2,Needs Maintenance,P0007,Emily Washington,Pvt,214
  E0008,"M16A3, M16A4",Needs Maintenance,P0008,Dakota King,Pvt,286
  E0009,HK416,Out of Service,P0009,Stacy Campbell,Pvt,205
  E0010,M27 IAR,Operational,P0010,Stacy Ellis,Pvt,223
  E0011,"Mk 16 Mod 0, Mk 17 Mod 0 SCAR",Out of Service,P0011,Donald Booth,Pvt,275
  E0012,M14,Operational,P0012,Chelsea Knight,Pvt,206
  E0013,HK417,Operational,P0013,Tiffany Jones MD,Pvt,262
  E0014,MCX,Out of Service,P0014,Tanya Alexander,Pvt,237
  E0015,XM7 NGSW,Needs Maintenance,P0015,Roberto Hill,Pvt,252
  E0016,Mk 14 EBR,Out of Service,P0016,Bruce Holland,Pvt,277
  E0017,M39 EMR,Operational,P0017,Michelle Peters,Pvt,229
  E0018,SDM-R,Operational,P0018,Rebecca Harrington,Pvt,251
  E0019,M110 SASS,Operational,P0019,Stephen Greer,Pvt,284
  E0020,M110A1 CSASS,Operational,P0020,Dawn Orr,Pvt,255
  E0021,M24 SWS,Out of Service,P0021,Michael Ward,Pvt,257
  E0022,M2010 ESR,Out of Service,P0022,Valerie Vargas,Pvt,221
  E0023,M40,Out of Service,P0023,Tyrone Carroll,Pvt,257
  E0024,M1903 Springfield,Operational,P0024,Madison Pena,Pvt,200
  E0025,Mk 13,Operational,P0025,Victoria Mcdonald,Pvt,214
  E0026,Mk 21 PSR,Out of Service,P0026,Eric Crawford,Pvt,268
  E0027,MK20 SSR,Operational,P0027,Angela Porter,Pvt,219
  E0028,Mk 22 ASR,Out of Service,P0028,Craig Martin,Pvt,256
  E0029,"Barrett 50 Cal/M82,M107",Out of Service,P0029,Victoria Trujillo,Pvt,229
  E0030,Shotguns,Out of Service,P0030,Sandra Rivas,Pvt,295
  E0031,M590,Operational,P0031,Curtis Reilly,Pvt,282
  E0032,Remington Model 870,Needs Maintenance,P0032,Paul Ryan,Pvt,233
  E0033,M1014,Operational,P0033,Alicia Stevenson,Pvt,275
  E0034,M26 MASS,Needs Maintenance,P0034,Whitney White,Pvt,280
  E0035,MP5,Operational,P0035,Savannah Chavez,Pvt,226
  E0036,MP7,Needs Maintenance,P0036,Dr. Timothy Roberts MD,Pvt,271
  E0037,APC9 Pro-K SCW,Needs Maintenance,P0037,Danny Powers,Pvt,243
  E0038,Machine guns,Operational,P0038,Eric Delacruz,Pvt,255
  E0039,M249 SAW,Needs Maintenance,P0039,Stephen Gallegos,Pvt,282
  E0040,M240,Operational,P0040,Christopher Hall,Pvt,228
  E0041,M60,Operational,P0041,Manuel Simpson,Pvt,276
  E0042,XM250,Needs Maintenance,P0042,Tanner Hudson,Pvt,260
  E0043,M9 Pistol,Operational,P0043,Valerie Roberts,Pvt,224
  E0044,M11,Needs Maintenance,P0044,Travis King,Pvt,237
  E0045,"M17, M18 MHS",Out of Service,P0045,Jennifer Hale,Pvt,229
  E0046,M1911,Operational,P0046,Eric Griffin,Pvt,244
  E0047,M1903 Springfield,Out of Service,P0047,Theresa Garrett DVM,Pvt,254
  E0048,M1 Garand,Needs Maintenance,P0048,Karen Oneal,Pvt,249
  E0049,M4 carbine/M4A1,Needs Maintenance,P0049,Cameron Knapp,Pvt,213
  E0050,M16A2,Operational,P0050,Kelsey Garcia,Pvt,208
  E0051,"M16A3, M16A4",Needs Maintenance,P0051,Katherine Hurley,Pvt,299
  E0052,HK416,Needs Maintenance,P0052,Mikayla Kelly,Pvt,242
  E0053,M27 IAR,Needs Maintenance,P0053,Melissa Graham,Pvt,232
  E0054,"Mk 16 Mod 0, Mk 17 Mod 0 SCAR",Operational,P0054,Katherine Conner,Pvt,279
  E0055,M14,Operational,P0055,Levi Rivera,Pvt,224
  E0056,HK417,Operational,P0056,Kenneth Becker,Pvt,224
  E0057,MCX,Operational,P0057,Nicholas Larson,Pvt,244
  E0058,XM7 NGSW,Operational,P0058,Brandon Richardson,Pvt,231
  E0059,Mk 14 EBR,Needs Maintenance,P0059,Crystal Holland,Pvt,287
  E0060,M39 EMR,Operational,P0060,Tammy Edwards,Pvt,208
  E0061,SDM-R,Needs Maintenance,P0061,Ricardo Soto,Pvt,293
  E0062,M110 SASS,Out of Service,P0062,Ryan Hartman,Pvt,239
  E0063,M110A1 CSASS,Out of Service,P0063,Tricia Mcclain,Pvt,250
  E0064,M24 SWS,Out of Service,P0064,Angelica Campbell,Pvt,287
  E0065,M2010 ESR,Needs Maintenance,P0065,Eddie Mayer,Pvt,257
  E0066,M40,Out of Service,P0066,Thomas Shaffer,Pvt,287
  E0067,M1903 Springfield,Needs Maintenance,P0067,Lucas Peterson,Pvt,247
  E0068,Mk 13,Needs Maintenance,P0068,Christine Cox,Pvt,284
  E0069,Mk 21 PSR,Out of Service,P0069,Michael Tapia,Pvt,289
  E0070,MK20 SSR,Needs Maintenance,P0070,Pam Bernard,Pvt,255
  E0071,Mk 22 ASR,Needs Maintenance,P0071,Ronald Velasquez,Pvt,214
  E0072,"Barrett 50 Cal/M82,M107",Needs Maintenance,P0072,Joshua Cordova,Pvt,238
  E0073,Shotguns,Needs Maintenance,P0073,Austin Morrison,Pvt,261
  E0074,M590,Needs Maintenance,P0074,Rebecca Perry,Pvt,271
  E0075,Remington Model 870,Operational,P0075,Tracy Goodwin,Pvt,212
  E0076,M1014,Needs Maintenance,P0076,Eric Cantu,Pvt,296
  E0077,M26 MASS,Operational,P0077,Erica Martinez,Pvt,211
  E0078,MP5,Out of Service,P0078,Jose Walker,Pvt,280
  E0079,MP7,Needs Maintenance,P0079,Krista Rogers,Pvt,239
  E0080,APC9 Pro-K SCW,Out of Service,P0080,Tracy Welch,Pvt,205
  E0081,Machine guns,Out of Service,P0081,Michael Rios,Pvt,207
  E0082,M249 SAW,Needs Maintenance,P0082,Monique Atkinson DVM,Pvt,225
  E0083,M240,Operational,P0083,Allen Chan,Pvt,296
  E0084,M60,Needs Maintenance,P0084,Trevor Mcconnell,Pvt,234
  E0085,XM250,Needs Maintenance,P0085,Barbara Horne,Pvt,208
  E0086,M9 Pistol,Needs Maintenance,P0086,Savannah Small,Pvt,267
  E0087,M11,Needs Maintenance,P0087,Rita Nicholson,Pvt,273
  E0088,"M17, M18 MHS",Out of Service,P0088,Kathy Jennings,Pvt,206
  E0089,M1911,Out of Service,P0089,Ronald Figueroa,Pvt,250
  E0090,M1903 Springfield,Operational,P0090,Stacey Cruz,Pvt,270
  E0091,M1 Garand,Operational,P0091,Dr. David Long,Pvt,254
  E0092,M4 carbine/M4A1,Out of Service,P0092,Elizabeth Delacruz,Pvt,243
  E0093,M16A2,Needs Maintenance,P0093,Joseph Vasquez,Pvt,267
  E0094,"M16A3, M16A4",Out of Service,P0094,Russell Stone,Pvt,213
  E0095,HK416,Out of Service,P0095,Gary Conley,Pvt,224
  E0096,M27 IAR,Out of Service,P0096,Heather Ayala,Pvt,224
  E0097,"Mk 16 Mod 0, Mk 17 Mod 0 SCAR",Out of Service,P0097,Jordan George,Pvt,281
  E0098,M14,Operational,P0098,Joseph Forbes,Pvt,290
  E0099,HK417,Needs Maintenance,P0099,Anne Taylor,Pvt,269
  E0100,MCX,Needs Maintenance,P0100,Jennifer Strickland,Cpl,235
  E0101,XM7 NGSW,Needs Maintenance,P0101,Maria Schwartz,Cpl,208
  E0102,Mk 14 EBR,Out of Service,P0102,Diana Hawkins,Cpl,210
  E0103,M39 EMR,Needs Maintenance,P0103,Tracey Freeman,Cpl,285
  E0104,SDM-R,Out of Service,P0104,Lindsay Olson,Cpl,275
  E0105,M110 SASS,Needs Maintenance,P0105,Cindy Williams,Cpl,294
  E0106,M110A1 CSASS,Needs Maintenance,P0106,Whitney Crawford,Cpl,271
  E0107,M24 SWS,Needs Maintenance,P0107,Steven Hahn,Cpl,257
  E0108,M2010 ESR,Operational,P0108,Gabriel Hood,Cpl,253
  E0109,M40,Operational,P0109,Bobby Welch,Cpl,274
  E0110,M1903 Springfield,Operational,P0110,Bailey Cohen MD,Cpl,271
  E0111,Mk 13,Out of Service,P0111,Reginald Garcia,Cpl,212
  E0112,Mk 21 PSR,Operational,P0112,Mark Ford,Cpl,221
  E0113,MK20 SSR,Operational,P0113,Isaiah Hall,Cpl,278
  E0114,Mk 22 ASR,Operational,P0114,Jeffrey Mccoy,Cpl,293
  E0115,"Barrett 50 Cal/M82,M107",Out of Service,P0115,Frank Williams,Cpl,207
  E0116,Shotguns,Needs Maintenance,P0116,Laura Kim,Cpl,236
  E0117,M590,Out of Service,P0117,Denise Key,Cpl,248
  E0118,Remington Model 870,Out of Service,P0118,Carrie Richardson,Cpl,235
  E0119,M1014,Out of Service,P0119,Kayla Morrow,Cpl,230
  E0120,M26 MASS,Operational,P0120,Christina Farley,Cpl,268
  E0121,MP5,Out of Service,P0121,Heather Byrd,Cpl,217
  E0122,MP7,Needs Maintenance,P0122,Danny Klein,Cpl,272
  E0123,APC9 Pro-K SCW,Operational,P0123,Tiffany Sherman,Cpl,261
  E0124,Machine guns,Needs Maintenance,P0124,Jamie Luna,Cpl,248
  E0125,M249 SAW,Operational,P0125,Claudia Smith,Cpl,238
  E0126,M240,Out of Service,P0126,Angela Potter DVM,Cpl,242
  E0127,M60,Needs Maintenance,P0127,Jill Erickson,Cpl,283
  E0128,XM250,Operational,P0128,Tammie Martin,Cpl,283
  E0129,M9 Pistol,Needs Maintenance,P0129,Christopher May,Cpl,282
  E0130,M11,Out of Service,P0130,Laura Gregory,Cpl,207
  E0131,"M17, M18 MHS",Out of Service,P0131,Francisco James,Cpl,224
  E0132,M1911,Operational,P0132,Dalton Thomas,Cpl,242
  E0133,M1903 Springfield,Needs Maintenance,P0133,Tracy Clark,Cpl,241
  E0134,M1 Garand,Operational,P0134,Carrie Mata,Cpl,267
  E0135,M4 carbine/M4A1,Needs Maintenance,P0135,Regina Welch,Cpl,227
  E0136,M16A2,Needs Maintenance,P0136,Sara Murray,Cpl,276
  E0137,"M16A3, M16A4",Out of Service,P0137,Debbie Solomon,Cpl,203
  E0138,HK416,Needs Maintenance,P0138,Jay Marshall,Cpl,237
  E0139,M27 IAR,Operational,P0139,Jason Bass,Cpl,251
  E0140,"Mk 16 Mod 0, Mk 17 Mod 0 SCAR",Out of Service,P0140,Stacey Sexton,Cpl,233
  E0141,M14,Out of Service,P0141,Jeremy Olson,Cpl,203
  E0142,HK417,Out of Service,P0142,Stephanie Rosario,Cpl,280
  E0143,MCX,Operational,P0143,Brian Osborn,Cpl,215
  E0144,XM7 NGSW,Out of Service,P0144,Melissa Flores,Cpl,297
  E0145,Mk 14 EBR,Needs Maintenance,P0145,Tara Noble,Cpl,205
  E0146,M39 EMR,Needs Maintenance,P0146,Jenna Bolton,Cpl,227
  E0147,SDM-R,Needs Maintenance,P0147,Justin Martin,Cpl,229
  E0148,M110 SASS,Out of Service,P0148,John Butler,Cpl,226
  E0149,M110A1 CSASS,Needs Maintenance,P0149,Carl Montgomery,Cpl,241
  E0150,M24 SWS,Out of Service,P0150,Dylan Lewis,Cpl,271
  E0151,M2010 ESR,Operational,P0151,Colleen Nelson,Cpl,214
  E0152,M40,Out of Service,P0152,Nathaniel Banks,Cpl,273
  E0153,M1903 Springfield,Out of Service,P0153,Brandon Ayala,Cpl,263
  E0154,Mk 13,Out of Service,P0154,Amy Abbott,Cpl,296
  E0155,Mk 21 PSR,Needs Maintenance,P0155,Ruben Fox,Cpl,251
  E0156,MK20 SSR,Needs Maintenance,P0156,Douglas Richards,Cpl,241
  E0157,Mk 22 ASR,Operational,P0157,Jenny Powell,Cpl,200
  E0158,"Barrett 50 Cal/M82,M107",Out of Service,P0158,Brady Pugh,Cpl,288
  E0159,Shotguns,Needs Maintenance,P0159,Andre Moore,Cpl,228
  E0160,M590,Operational,P0160,Bridget Smith,Cpl,216
  E0161,Remington Model 870,Operational,P0161,Jacob Kane,Cpl,273
  E0162,M1014,Operational,P0162,Nathaniel Washington,Cpl,278
  E0163,M26 MASS,Out of Service,P0163,David Vance,Cpl,300
  E0164,MP5,Needs Maintenance,P0164,Diane Park,Cpl,214
  E0165,MP7,Out of Service,P0165,Brooke Munoz,Cpl,225
  E0166,APC9 Pro-K SCW,Needs Maintenance,P0166,Brittney Tyler,Cpl,210
  E0167,Machine guns,Needs Maintenance,P0167,Jeffrey Fry,Cpl,219
  E0168,M249 SAW,Needs Maintenance,P0168,Carla Harris,Cpl,213
  E0169,M240,Needs Maintenance,P0169,Krista Wilson,Cpl,210
  E0170,M60,Out of Service,P0170,Timothy Ochoa,Cpl,210
  E0171,XM250,Needs Maintenance,P0171,Jennifer Hatfield,Cpl,237
  E0172,M9 Pistol,Operational,P0172,Maria Herman,Cpl,226
  E0173,M11,Operational,P0173,Scott Salas,Cpl,293
  E0174,"M17, M18 MHS",Needs Maintenance,P0174,Kenneth Rodriguez,Cpl,284
  E0175,M1911,Operational,P0175,Victoria Mahoney,Cpl,238
  E0176,M1903 Springfield,Needs Maintenance,P0176,Jeremy Beard,Cpl,208
  E0177,M1 Garand,Operational,P0177,Daniel Peterson,Cpl,221
  E0178,M4 carbine/M4A1,Out of Service,P0178,Daniel Rivas,Cpl,255
  E0179,M16A2,Out of Service,P0179,Frank Hart,Cpl,260
  E0180,"M16A3, M16A4",Out of Service,P0180,Sophia Powell,Sgt,219
  E0181,HK416,Out of Service,P0181,Ricardo Pena,Sgt,269
  E0182,M27 IAR,Needs Maintenance,P0182,Kerry Martinez,Sgt,289
  E0183,"Mk 16 Mod 0, Mk 17 Mod 0 SCAR",Needs Maintenance,P0183,Max Bush,Sgt,285
  E0184,M14,Operational,P0184,Stephanie Diaz,Sgt,299
  E0185,HK417,Operational,P0185,Philip Perez,Sgt,289
  E0186,MCX,Needs Maintenance,P0186,Robert Conway,Sgt,262
  E0187,XM7 NGSW,Needs Maintenance,P0187,Bernard Lee,Sgt,210
  E0188,Mk 14 EBR,Needs Maintenance,P0188,Charles Valdez,Sgt,221
  E0189,M39 EMR,Needs Maintenance,P0189,Jonathan Barrett,Sgt,273
  E0190,SDM-R,Operational,P0190,Donna Sutton,Sgt,286
  E0191,M110 SASS,Operational,P0191,Katrina Leonard,Sgt,225
  E0192,M110A1 CSASS,Operational,P0192,Theodore Hall,Sgt,299
  E0193,M24 SWS,Needs Maintenance,P0193,Corey Sanders,Sgt,267
  E0194,M2010 ESR,Out of Service,P0194,Mary Ward,Sgt,208
  E0195,M40,Operational,P0195,Keith Hubbard,Sgt,283
  E0196,M1903 Springfield,Operational,P0196,Joyce Knight,Sgt,298
  E0197,Mk 13,Needs Maintenance,P0197,Kelli Smith,Sgt,221
  E0198,Mk 21 PSR,Out of Service,P0198,Peter Fitzgerald,Sgt,269
  E0199,MK20 SSR,Out of Service,P0199,Steven Burton,Sgt,291
 `,
  "resource_deployment_forecasting.csv": `Unit_ID,Unit_Name,Readiness_Rating,Location,AvgTrainingScore,NumberOfTrainedPersonnel
  U02,Charlie,9.7548470887277,Janetmouth,0,0
  U01,Bravo,2.6017904938052356,Portershire,0,0
  U03,Delta,8.534870438730941,East Michellemouth,0,0
  U00,Alpha,4.041947447138713,Valentinemouth,0,0
  `,
  "training_effectiveness_impact.csv": `Personnel_ID,Rank,Specialty_MOS,Deployment_History,AvgTrainingScore,TotalTrainings
  P0144,Cpl,Infantry,5,97,1
  P0364,Major,Pilot,5,91,1
  P0499,Pvt,Infantry,1,90,1
  P1268,Major,Infantry,5,78,1
  P1362,Pvt,Engineer,5,99,1
  P1506,Cpl,Artillery,5,73,1
  P2111,Lt,Engineer,4,85,1
  P2126,Capt,Engineer,2,85,1
  P2245,Col,Artillery,4,75,1
  P2402,Cpl,Infantry,5,93,1
  P2783,Pvt,Infantry,5,86,1
  P2992,Lt,Artillery,3,73,1
  P3144,LtCol,Infantry,3,96,1
  P3265,Pvt,Communications,3,100,1
  P3451,Lt,Artillery,3,91,1
  P3472,Lt,Infantry,3,82,1
  P4081,Pvt,Medic,3,76,1
  P4163,Pvt,Artillery,4,88,1
  P4344,Lt,Engineer,0,90,1
  P4430,Capt,Medic,2,86,1
  P4886,Capt,Artillery,0,98,1
  P5197,Sgt,Medic,0,74,1
  P5372,Major,Infantry,2,87,1
  P5444,Pvt,Engineer,1,78,1
  P5451,Pvt,Engineer,0,81,1
  P5596,Cpl,Pilot,3,74,1
  P6341,Col,Pilot,2,72,1
  P6406,Pvt,Artillery,2,95,1
  P6561,Sgt,Engineer,3,71,1
  P6608,Lt,Engineer,4,98,1
  P6745,LtCol,Artillery,2,79,1
  P6836,Pvt,Infantry,1,81,1
  P6932,Cpl,Engineer,5,94,1
  P6989,Sgt,Engineer,1,98,1
  P7015,Sgt,Communications,3,99,1
  P7319,Pvt,Engineer,1,89,1
  P7320,Pvt,Engineer,3,89,1
  P7400,Cpl,Communications,5,71,1
  P7471,Sgt,Pilot,2,98,1
  P7707,Pvt,Engineer,3,88,1
  P7882,Sgt,Artillery,4,95,1
  P7922,Sgt,Artillery,1,96,1
  P8042,Capt,Engineer,4,87,1
  P8169,Pvt,Communications,1,92,1
  P8312,Cpl,Artillery,0,96,1
  P8502,Capt,Communications,0,96,1
  P8642,Pvt,Artillery,1,74,1
  P8655,Pvt,Artillery,3,96,1
  P8682,Pvt,Artillery,3,93,1
  P8700,Pvt,Artillery,2,71,1
  P8776,Cpl,Infantry,0,73,1
  P8795,Sgt,Engineer,3,87,1
  P8816,Sgt,Engineer,5,90,1
  P9503,Col,Infantry,4,88,1
  P9599,Pvt,Engineer,2,72,1
  P10364,Major,Engineer,1,73,1
  P10825,LtCol,Pilot,1,85,1
  P11131,Lt,Communications,3,71,1
  P11140,Lt,Communications,4,77,1
  P11269,Major,Artillery,3,82,1
  P11299,LtCol,Artillery,0,82,1
  P11353,Pvt,Infantry,0,75,1
  P11587,Lt,Pilot,0,75,1
  P11797,Pvt,Engineer,0,100,1
  P11962,Sgt,Pilot,4,97,1
  P12025,Sgt,Artillery,2,72,1
  P12124,Capt,Infantry,4,94,1
  P12576,Capt,Engineer,1,84,1
  P12682,Col,Medic,0,79,1
  P12728,Pvt,Artillery,3,87,1
  P12861,Cpl,Infantry,1,71,1
  P13644,Pvt,Infantry,3,98,1
  P13951,Capt,Infantry,1,98,1
  P14001,LtCol,Infantry,3,77,1
  P14259,Sgt,Artillery,0,86,1
  P14539,Pvt,Medic,5,100,1
  P14673,Cpl,Artillery,2,77,1
  P14783,Lt,Engineer,2,81,1
  P15107,Cpl,Infantry,5,88,1
  P15245,Lt,Communications,5,76,1
  P15267,Capt,Communications,0,84,1
  P15298,Capt,Medic,5,97,1
  P15341,Major,Artillery,3,74,1
  P16072,Sgt,Pilot,5,89,1
  P16082,Sgt,Artillery,3,94,1
  P17166,LtCol,Medic,3,70,1
  P17299,Pvt,Artillery,5,94,1
  P17490,Lt,Communications,2,87,1
  P17811,Cpl,Engineer,4,85,1
  P18089,LtCol,Infantry,2,85,1
  P18231,Cpl,Communications,1,73,1
  P18454,Capt,Infantry,4,98,1
  P18480,Capt,Infantry,0,82,1
  P18510,Major,Infantry,3,80,1
  P18590,Pvt,Engineer,5,88,1
  P18837,Lt,Infantry,5,86,1
  P18984,LtCol,Communications,5,89,1
  P19163,Cpl,Artillery,5,98,1
  P19220,Sgt,Infantry,0,72,1
  P19328,Lt,Engineer,0,96,1
  P21004,Cpl,Artillery,1,74,1
  P21038,Sgt,Artillery,2,92,1
  P21301,Pvt,Pilot,0,78,1
  P21608,Capt,Communications,5,87,1
  P21621,Capt,Communications,2,87,1
  P21836,Pvt,Infantry,4,89,1
  P21934,Sgt,Engineer,0,74,1
  P22222,Pvt,Infantry,5,92,1
  P22245,Pvt,Infantry,4,78,1
  P22264,Pvt,Engineer,3,91,1
  P22706,Pvt,Engineer,4,96,1
  P22779,Cpl,Medic,2,78,1
  P22879,Sgt,Artillery,0,72,1
  P23038,Major,Engineer,4,85,1
  P23274,Cpl,Artillery,2,86,1
  P23751,Sgt,Engineer,0,97,1
  P23900,Capt,Medic,1,84,1
  P23937,Major,Artillery,5,89,1
  P24384,Major,Artillery,1,96,1
  P24679,Sgt,Pilot,0,84,1
  P24971,Pvt,Communications,3,85,1
  P25032,Cpl,Medic,1,93,1
  P25611,Sgt,Infantry,5,92,1
  P25747,Major,Communications,0,78,1
  P25971,Cpl,Infantry,4,77,1
  P26060,Sgt,Engineer,4,86,1
  P26137,Capt,Medic,1,91,1
  P26317,Pvt,Infantry,0,77,1
  P26327,Pvt,Infantry,3,84,1
  P26744,Pvt,Engineer,3,97,1
  P27029,Lt,Infantry,3,81,1
  P27069,Capt,Infantry,1,84,1
  P27212,Pvt,Communications,3,83,1
  P27250,Pvt,Medic,2,90,1
  P27393,Sgt,Artillery,2,70,1
  P27749,Cpl,Artillery,0,94,1
  P27883,Lt,Engineer,0,97,1
  P28020,Major,Medic,3,87,1
  P28087,Pvt,Artillery,1,70,1
  P28117,Pvt,Artillery,1,100,1
  P28151,Pvt,Infantry,3,99,1
  P28170,Pvt,Infantry,0,89,1
  P28181,Pvt,Infantry,5,89,1
  P28184,Pvt,Infantry,1,80,1
  P28830,Lt,Artillery,0,73,1
  P29256,Lt,Artillery,3,90,1
  P29426,Col,Engineer,5,91,1
  P29479,Pvt,Communications,1,90,1
  P29915,Pvt,Pilot,0,75,1
  P30012,Cpl,Artillery,3,85,1
  P30175,Lt,Engineer,1,71,1
  P30299,Major,Artillery,3,87,1
  P30330,LtCol,Artillery,5,95,1
  P30445,Pvt,Infantry,4,82,1
  P30614,Lt,Medic,0,72,1
  P30674,Capt,Artillery,4,71,1
  P30753,Major,Artillery,2,73,1
  P30891,Pvt,Engineer,4,89,1
  P31177,Major,Infantry,1,79,1
  P31219,LtCol,Infantry,2,99,1
  P31323,Pvt,Communications,3,92,1
  P31486,Sgt,Artillery,2,95,1
  P32025,Capt,Engineer,0,72,1
  P32097,Major,Communications,5,97,1
  P32627,Pvt,Artillery,1,80,1
  P34550,Cpl,Infantry,0,86,1
  P34651,Sgt,Engineer,3,95,1
  P35113,Sgt,Medic,1,76,1
  P35478,Cpl,Medic,5,97,1
  P35584,Lt,Artillery,0,74,1
  P35860,Pvt,Medic,3,87,1
  P36006,Sgt,Infantry,5,82,1
  P36643,LtCol,Pilot,4,94,1
  P36802,Cpl,Infantry,5,100,1
  P36831,Cpl,Infantry,4,85,1
  P37571,LtCol,Infantry,5,80,1
  P37719,Cpl,Medic,2,98,1
  P37794,Sgt,Artillery,3,79,1
  P37826,Sgt,Artillery,1,99,1
  P37849,Lt,Artillery,1,73,1
  P37901,Lt,Infantry,3,93,1
  P39142,Sgt,Engineer,1,75,1
  P39165,Sgt,Communications,0,86,1
  P39460,Pvt,Engineer,0,98,1
  P39487,Pvt,Engineer,4,75,1
  P39662,Lt,Artillery,3,80,1
  P39907,Pvt,Engineer,0,95,1
  P39931,Pvt,Communications,3,95,1
  P40004,Cpl,Pilot,5,90,1
  P40084,Sgt,Artillery,0,82,1
  P40385,Pvt,Pilot,1,77,1
  P40747,LtCol,Pilot,3,76,1
  P40770,Pvt,Artillery,0,81,1
  P40802,Pvt,Artillery,0,97,1
  P40921,Cpl,Infantry,5,73,1
  P41112,Capt,Medic,2,81,1
  P41607,Major,Artillery,2,99,1
  P41663,Col,Infantry,4,73,1
  P41685,Pvt,Infantry,3,85,1
  P41794,Cpl,Communications,1,75,1
  `,
  "unit_readiness_resource_allocation.csv": `Unit_ID,Unit_Name,Readiness_Rating,Location,TotalPersonnel,UnitAvgFitnessScore
  U00,Alpha,4.041947447138713,Valentinemouth,24903,249.87294703449385
  U03,Delta,8.534870438730941,East Michellemouth,25054,249.77045581543865
  U02,Charlie,9.7548470887277,Janetmouth,25076,249.73668049130643
  U01,Bravo,2.6017904938052356,Portershire,24967,249.7724596467337
  `,
  "army_personnel_readiness_report_by_location.csv": `Location,Total_Personnel_Count
  East Michellemouth,25054
  Janetmouth,25076
  Valentinemouth,24903
  Portershire,24967
  `,
  "army_personnel.csv": `Personnel_ID,Name,Rank,Specialty_MOS,Foreign_Language,Unit,Physical_Fitness_Score,Medical_Readiness_Status,Deployment_History,Training_Completion_Status,Clearance_Level,Date_of_Last_Deployment,Scheduled_Training_Dates
P0000,Melissa Hardin,Pvt,Artillery,Russian,Bravo,232,Fit,1,Complete,Confidential,2021-06-16,2024-02-05
P0001,Jennifer Black,Pvt,Artillery,Russian,Bravo,207,Fit,2,Complete,Confidential,2019-04-18,2024-04-11
P0002,Denise Charles MD,Pvt,Artillery,Russian,Bravo,252,Fit,5,Complete,Confidential,2023-06-13,2024-11-14
P0003,Leroy Phillips,Pvt,Artillery,Russian,Delta,208,Fit,3,Complete,Confidential,2019-10-18,2024-12-11
P0004,Keith Clayton,Pvt,Artillery,Russian,Charlie,222,Fit,0,Complete,Confidential,2019-04-02,2024-08-06
P0005,Stacy Jacobs,Pvt,Artillery,Russian,Delta,282,Fit,0,Complete,Confidential,2022-04-24,2024-05-01
P0006,Taylor Steele,Pvt,Artillery,Russian,Bravo,246,Fit,3,Complete,Confidential,2021-08-21,2024-09-07
P0007,Emily Washington,Pvt,Artillery,Russian,Delta,214,Fit,1,Complete,Confidential,2019-11-28,2024-01-16
P0008,Dakota King,Pvt,Artillery,Russian,Bravo,286,Fit,0,Complete,Confidential,2022-04-03,2024-07-10
P0009,Stacy Campbell,Pvt,Artillery,Russian,Alpha,205,Fit,0,Complete,Confidential,2022-09-17,2024-01-13
P0010,Stacy Ellis,Pvt,Artillery,Russian,Charlie,223,Fit,2,In-Progress,Confidential,2020-12-09,2024-10-23
P0011,Donald Booth,Pvt,Artillery,Russian,Delta,275,Fit,1,In-Progress,Confidential,2021-05-10,2024-06-12
P0012,Chelsea Knight,Pvt,Artillery,Russian,Delta,206,Fit,2,In-Progress,Confidential,2022-07-25,2024-09-12
P0013,Tiffany Jones MD,Pvt,Artillery,Russian,Charlie,262,Fit,2,In-Progress,Confidential,2023-12-25,2024-08-12
`,
  "army_equipment.csv": `Equipment_ID,Personnel_ID,Equipment_Type,Equipment_Category,Equipment_Status,Date_of_Last_Service,Expected_Service_Date
E0000,P0000,M9 Pistol,Semi-automatic pistol,Out of Service,2023-01-31,1/8/24
E0001,P0001,M11,Semi-automatic pistol,Needs Maintenance,2023-04-26,1/8/24
E0002,P0002,M17, M18 MHS,Semi-automatic pistol,Needs Maintenance,2023-11-03,1/8/24
E0003,P0003,M1911,Semi-automatic pistol,Operational,2023-07-02,1/8/24
E0004,P0004,M1903 Springfield,Bolt-action rifle,Needs Maintenance,2023-06-07,1/8/24
E0005,P0005,M1 Garand,Semi-automatic rifle,Out of Service,2023-05-14,1/8/24
E0006,P0006,M4 carbine/M4A1,Assault rifle,Carbine,Needs Maintenance,2023-10-31,1/8/24
E0007,P0007,M16A2,Assault rifle,Needs Maintenance,2023-07-23,1/8/24
E0008,P0008,M16A3, M16A4,Assault rifle,Needs Maintenance,2023-02-13,1/8/24
E0009,P0009,HK416,Assault rifle,Out of Service,2023-02-28,1/8/24
E0010,P0010,M27 IAR,Assault rifle,Squad automatic weapon,Operational,2023-04-28,1/8/24
E0011,P0011,Mk 16 Mod 0, Mk 17 Mod 0 SCAR,Assault rifle(SCAR L)Battle rifle(SCAR H),Out of Service,2023-11-02,1/8/24
E0012,P0012,M14,Battle rifle,Operational,2023-02-06,1/8/24
E0013,P0013,HK417,Battle rifle,Operational,2023-10-28,1/8/24
E0014,P0014,MCX,Assault rifle,Out of Service,2023-12-20,1/8/24
E0015,P0015,XM7 NGSW,Assault rifle,Needs Maintenance,2023-02-07,1/8/24`,
  army_readiness: `Unit_ID,Unit_Name,Location,Personnel_Count,Readiness_Rating,Last_Deployment_Date,Next_Scheduled_Deployment,Training_Status
U00,Alpha,Valentinemouth,24903,4.041947447138713,2022-11-17,2024-10-16,Scheduled
U01,Bravo,Portershire,24967,2.6017904938052356,2023-08-09,2024-02-19,Scheduled
U02,Charlie,Janetmouth,25076,9.7548470887277,2019-05-29,2024-06-02,Overdue
U03,Delta,East Michellemouth,25054,8.534870438730941,2019-01-10,2024-02-15,Overdue`,
  "army_training.csv": `Training_ID,Personnel_ID,Training_Name,Training_Type,Status,Certification_Date,Expiration_Date,Score
T0000,P0000,engage best-of-breed bandwidth,Advanced,Completed,2022-08-27,2025-09-21,89
T0001,P0001,deliver strategic experiences,Specialized,Completed,2023-03-12,2025-06-05,84
T0002,P0002,seize user-centric communities,Specialized,Scheduled,2022-02-15,2024-09-24,87
T0003,P0003,maximize integrated eyeballs,Basic,Overdue,2023-01-31,2025-02-17,97
T0004,P0004,harness dynamic e-markets,Advanced,Overdue,2023-05-03,2024-03-20,92
T0005,P0005,mesh leading-edge applications,Basic,Scheduled,2022-09-02,2025-06-15,99
T0006,P0006,exploit best-of-breed e-markets,Advanced,Completed,2023-01-10,2024-12-08,70
T0007,P0007,maximize bricks-and-clicks e-tailers,Advanced,Completed,2022-12-15,2024-06-16,79
T0008,P0008,optimize extensible deliverables,Basic,Overdue,2022-03-28,2025-03-12,81
T0009,P0009,integrate open-source e-commerce,Advanced,Completed,2023-12-25,2025-05-04,89
T0010,P0010,synthesize back-end bandwidth,Specialized,Overdue,2023-08-26,2025-10-24,92
T0011,P0011,deliver integrated applications,Basic,Completed,2023-04-29,2025-11-20,82
T0012,P0012,aggregate distributed networks,Basic,Completed,2022-06-18,2026-01-05,92
T0013,P0013,grow virtual paradigms,Basic,Completed,2022-08-29,2024-05-13,88
T0014,P0014,drive bricks-and-clicks vortals,Specialized,Scheduled,2022-09-25,2025-12-24,93
T0015,P0015,productize bleeding-edge eyeballs,Specialized,Scheduled,2022-12-12,2024-12-30,77
`,
};
