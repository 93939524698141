import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Paper,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { search, submitFeedbackForm } from "../../../api/data-products";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import DeleteIcon from "@mui/icons-material/Delete";

const FeedbackSection = ({ dataProduct }) => {
  const [newFeedback, setNewFeedback] = useState("");
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = useCallback((e) => {
    const { value } = e.target;
    setNewFeedback(value);
  }, []);

  const handleClear = () => {
    setNewFeedback("");
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const token = localStorage.getItem("OAUTH");
      if (token) {
        const decodedToken = jwtDecode(JSON.parse(token)?.access_token);
        const createdBy = decodedToken.name || decodedToken.sub;
        const feedbackRecord = {
          comment: newFeedback,
          createdBy: createdBy,
          createdAt: moment().toISOString(),
        };
        if (dataProduct?.uuid && feedbacks) {
          try {
            await submitFeedbackForm(dataProduct.uuid, [
              feedbackRecord,
              ...feedbacks,
            ]);
            setFeedbacks((prev) => [feedbackRecord, ...prev]);
            setNewFeedback("");
          } catch (error) {
            console.error("Error submitting feedback:", error);
          }
        }
      } else {
        console.error("Token not found in local storage");
      }
    },
    [dataProduct, feedbacks, newFeedback]
  );

  const handleDeleteFeedback = async (index) => {
    try {
      const updatedFeedbacks = [...feedbacks];
      updatedFeedbacks.splice(index, 1);
      setFeedbacks(updatedFeedbacks);
      await submitFeedbackForm(dataProduct.uuid, updatedFeedbacks);
    } catch (error) {
      console.error("Error deleting feedback:", error);
    }
  };

  useEffect(() => {
    if (dataProduct?.uuid) {
      const getFeedback = async () => {
        setLoading(true);
        try {
          const { data, error } = await search({
            searchValue: {
              _id: dataProduct.uuid,
            },
          });
          if (error) {
            console.error("Error fetching feedback:", error);
            return;
          }
          const feedbackRecords = data?.results[0]?._source?.feedbacks || [];
          if (feedbackRecords) {
            setFeedbacks(feedbackRecords);
          }
        } catch (err) {
          console.error("Unexpected error fetching feedback:", err);
        } finally {
          setLoading(false);
        }
      };
      getFeedback();
    }
  }, [dataProduct]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          multiline
          rows={3}
          value={newFeedback}
          onChange={handleChange}
          margin="normal"
          placeholder="Enter some feedback..."
          required
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
        <Button
          onClick={handleClear}
          variant="contained"
          color="secondary"
          sx={{ ml: 1 }}
        >
          Clear
        </Button>
      </form>
      <Box mt={4}>
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : feedbacks?.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>FEEDBACK</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>CREATED BY</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>CREATED AT</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feedbacks.map((fb, index) => (
                  <TableRow key={index}>
                    <TableCell>{fb.comment}</TableCell>
                    <TableCell>{fb.createdBy}</TableCell>
                    <TableCell>{fb.createdAt}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeleteFeedback(index)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body1">No feedbacks available.</Typography>
        )}
      </Box>
    </>
  );
};

export default FeedbackSection;
