import React from "react";
import LoginCallback from "./auth/components/LoginCallback";
import Logout from "./auth/components/Logout"
import Login from "./auth/components/Login";
import { HOME_URL, LOGIN_CALLBACK_PATH, LOGIN_PATH, LOGOUT_PATH } from "./auth/config";
import { SearchPage } from "./pages";

const routes = [
  {
    path: HOME_URL,
    element: <SearchPage/>,
    isPrivate: true,
  },
  // Auth specific routes
  
  {
    path: LOGIN_CALLBACK_PATH,
    element: <LoginCallback/>,
  },
  {
    path: LOGOUT_PATH,
    element: <Logout/>,
  },
  {
    path: LOGIN_PATH,
    element: <Login/>,
  },
  {
    path: "/",
    element: <Login/>,
  },
];

export default routes