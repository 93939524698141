const PUBLIC_ACCESS = "public";
const PRIVATE_ACCESS = "private";

const HUMAN_MESSAGE_TYPE = "humanMessage";
const AI_MESSAGE_TYPE = "aiMessage";
const PRESET_API_KEY = "test_key";
const TEST_KEY_OPTION_VALUE_MODEL_SETTINGS = "Use Test Key";
const SELF_KEY_OPTION_VALUE_MODEL_SETTINGS = "Self";
const LLM_MODEL_VALUE_V1 = "gpt-3.5-turbo";
const LLM_MODEL_VALUE_V2 = "gpt-4";
const AI_LABEL = "nü(AI)de";
const USER_LABEL = "User";

export {
  PUBLIC_ACCESS,
  PRIVATE_ACCESS,
  HUMAN_MESSAGE_TYPE,
  AI_MESSAGE_TYPE,
  PRESET_API_KEY,
  TEST_KEY_OPTION_VALUE_MODEL_SETTINGS,
  SELF_KEY_OPTION_VALUE_MODEL_SETTINGS,
  LLM_MODEL_VALUE_V1,
  LLM_MODEL_VALUE_V2,
  AI_LABEL,
  USER_LABEL,
};
