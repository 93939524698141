import { green, grey, orange, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#017A49',
      light: '#ECFCF4',
      dark: '#01623A',
      contrastText: '#fff',
    },
    secondary: {
      main: '#0090B8',
    },
    danger: orange[500],
    error: {
      main: red[700],
      light: red[400],
      dark: red[800],
    },
    info: {
      main: grey[700],
      light: grey[500],
      dark: grey[900],
    },
    success: {
      main: green[800],
      light: green[500],
      dark: green[900],
    },
    warning: {
      main: "#ed6c02",
      light: orange[500],
      dark: orange[900],
    },
  },
  typography: {
    fontFamily: [
      'DM Sans'
    ].join(',')
  }
});

export {
  theme
}